import baseReducer from "../../base/store/reducer";
import constants from "./constants";

const reducer = (state, action) => {
  const { type, payload } = action;
  const newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.SET_ANON_COMPANY_DATA:
      return {
        ...newState,
        anonCompanyData: payload.data,
        isAnonLoading: false,
      };
    case constants.SET_ANON_LOADING:
      return { ...newState, isAnonLoading: true };
    default:
      return newState;
  }
};

export default reducer;
