import constants from "./constants";

const INITIAL_STATE = {
  errorStatus: null,
  localAuthenticating: false,
  hasAttemptedLogin: false,
  authenticating: false,
  authorization: null,
  userId: null,
  tenantPortalSetting: null,
  error: null,
  loggedOut: false,
  showMfaSetting: false,
  enforceMfaSetting: false,
  user: {},
  // Bare mode hides all but the rendered view
  isBareMode: false,

  // content size helpers
  isMobile: false,
  isSmallDesktop: false,

  loadingPerms: false,
  isAvyAuth: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case constants.START_LOGIN:
      return handleStartLogin(state);
    case constants.LOGIN_SUCCESSFUL:
      return handleSuccessfulLogin(state, payload);
    case constants.LOGIN_FAILED:
      return handleFailedLogin(state, payload);
    case constants.START_LOCAL_LOGIN:
      return handleStartLocalLogin(state);
    case constants.LOCAL_LOGIN_SUCCESSFUL:
      return handleSuccessfulLogin(state, payload);
    case constants.LOCAL_LOGIN_FAILED:
      return handleLocalLoginFailed(state);
    case constants.LOG_OUT:
      return handleLogout(state);
    case constants.GET_USER:
      return handleGetUser(state, payload);
    case constants.SET_BARE_MODE:
      return { ...state, isBareMode: payload.bareMode };
    case constants.SET_TPS: {
      return { ...state, tenantPortalSetting: payload.tenantPortalSetting };
    }
    case constants.SET_LOADING_PERMS:
      return { ...state, loadingPerms: payload.loadingPerms };
    case constants.SET_PERMS:
      return handleUserPerms(state, payload);
    case constants.SET_SCREEN_SIZE:
      const { isMobile, isSmallDesktop } = payload;
      return { ...state, isMobile, isSmallDesktop };
    case constants.MFA_SETTING: {
      return {
        ...state,
        showMfaSetting: payload.show,
        enforceMfaSetting: payload.enforce || false,
      };
    }
    case constants.MFA_PENDING: {
      return { ...state, authenticating: false, errorStatus: null };
    }
    default:
      return state;
  }
};

const handleStartLogin = (state) => {
  return {
    ...state,
    authenticating: true,
    errorStatus: null,
  };
};

const handleSuccessfulLogin = (state, payload) => {
  return {
    ...state,
    authorization: payload.token,
    userId: payload.userId,
    tenantPortalSetting: payload.tenant_portal_setting,
    loggedOut: false,
    authenticating: false,
    localAuthenticating: false,
    errorStatus: null,
    hasAttemptedLogin: true,
    intercom: payload.intercom,
    isAvyAuth: payload?.isAvyAuth || false,
  };
};

const handleFailedLogin = (state, payload) => {
  return {
    ...state,
    authenticating: false,
    errorStatus: payload.error,
    loggedOut: true,
    authorization: null,
    hasAttemptedLogin: true,
  };
};

const handleStartLocalLogin = (state) => {
  return {
    ...state,
    localAuthenticating: true,
  };
};

const handleLocalLoginFailed = (state) => {
  return {
    ...state,
    loggedOut: true,
    localAuthenticating: false,
    authorization: null,
    hasAttemptedLogin: true,
    tenantPortalSetting: null,
  };
};

const handleLogout = (state) => {
  return {
    ...state,
    authorization: null,
    userId: null,
    loggedOut: true,
    tenantPortalSetting: null,
  };
};

const handleGetUser = (state, payload) => {
  return {
    ...state,
    user: payload.user,
  };
};

const handleUserPerms = (state, payload) => {
  return {
    ...state,
    user: { ...(state.user ?? {}), permissions: payload.permissions },
    loadingPerms: false,
  };
};
