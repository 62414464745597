import styled, { css } from "styled-components";
import burgerIcon from "../../assets/svg/menu_white.svg";
import closeIcon from "../../assets/svg/close-circle.svg";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  width: 100vw;
  padding: 10px 12px;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (min-width: 769px) {
    margin-left: 260px;
    width: calc(100vw - 260px);
    background-color: rgba(0, 0, 0, 0);
  }
`;

export const BurgerButton = styled.button`
  cursor: pointer;
  height: 36px;
  position: relative;
  width: 36px;
  min-width: 36px;
  background-image: url(${burgerIcon});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  white-space: nowrap;
  border: none;
  box-sizing: border-box;
`;

export const IconButton = styled.div`
  position: relative;
  cursor: pointer;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-color: #fff;
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80%;
  background-image: url(${(p) => p.icon});
  margin-left: 8px;

  ${(p) =>
    p.notReadCount > 0 &&
    css`
      &:after {
        position: absolute;
        content: "";
        top: -3px;
        right: -3px;
        height: 10px;
        width: 10px;

        border-radius: 50%;
        background-color: ${(p) => p.theme.colors.primaryBlue};
      }
    `}
`;

export const ProfileBadgeWrapper = styled.div`
  cursor: pointer;
  padding: 2px 6px;
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  border-radius: 24px;
  display: flex;
  background-color: rgba(256, 256, 256, 0.8);
  align-items: center;

  @media (min-width: 769px) {
    padding: 2px 6px 2px 12px;
  }
`;

export const ProfileBadgeTitle = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
`;

export const ProfileBadge = styled.div`
  cursor: pointer;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-color: #fff;
  border: ${(p) => p.theme.borders.standard};
  box-shadow: ${(p) => p.theme.boxShadows.standard};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(p) =>
    `https://eu.ui-avatars.com/api/?name=${
      p.name || ""
    }&background=253237&color=ffffff`});
  margin-left: 8px;
`;

export const MobileMenu = styled.div`
  width: 100vw;
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 12px 36px 12px 36px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  box-shadow: ${(p) => p.theme.boxShadows.standard};

  transition: all 0.3s ease-in-out;

  ${(p) =>
    !p.isOpen &&
    css`
      transform: translateX(-100%);
    `}
`;

export const MobileMenuTopBar = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
`;

export const CloseButton = styled.button`
  cursor: pointer;
  height: 36px;
  position: relative;
  width: 36px;
  min-width: 36px;
  background-image: url(${closeIcon});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  white-space: nowrap;
  border: none;
  box-sizing: border-box;
`;

export const MenuItem = styled(Link)`
  display: flex;
  padding: 12px 36px;
  margin-bottom: 6px;
  border-radius: 6px;
  align-items: center;
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  position: relative;
  background-color: ${(p) =>
    p.active ? p.theme.colors.primaryFaded : "transparent"};

  color: ${(p) =>
    p.active ? p.theme.colors.primaryBlue : p.theme.colors.primaryText};

  text-decoration: none; /* no underline */
  -webkit-tap-highlight-color: transparent;

  &:before {
    content: "";
    position: absolute;
    left: 6px;
    top: calc(50% -11px);
    height: 22px;
    width: 22px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${(p) => p.icon});
    margin-right: 6px;
  }

  &:hover {
    background-color: ${(p) =>
      p.active ? p.theme.colors.primaryFaded : p.theme.colors.gray1};
  }

  @media (max-width: 768px) {
    font-weight: ${(p) => p.theme.fontWeights.headerSmall};
    font-size: ${(p) => p.theme.fontSizes.headerSmall};
  }
`;

export const DekstopMenuWrapper = styled.div`
  width: 260px;
  min-height: 100vh;
  background-color: ${(p) => p.theme.colors.backgroundGray};
  border-right: thin solid #dddddd;
  padding: 12px 16px 12px 16px;
  z-index: 103;
`;

export const DesktopTitle = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerMedium};
  color: #fff;
`;

export const DesktopSubtitle = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  font-size: ${(p) => p.theme.fontSizes.headerSmall};
  color: #fff;
`;
