import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../store/app/actions";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../store/base";
import { useUserForm, constants, update } from "../../store/users";

import PrimaryButton from "../Buttons/PrimaryButton";
import TextButton from "../Buttons/TextButton";
import OverlaySpinner from "../Content/Loaders/OverlaySpinner";
import TextInput from "./TextInput";

export default function UserForm({ onCheckout }) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const method = "PATCH";
  const [loading, setLoading] = React.useState(false);
  const [hasSetInitial, setHasSetInitial] = React.useState(false);
  const user = useSelector((state) => state.app.user);
  useUserForm("PATCH", user?.id);

  React.useEffect(() => {
    if (user && !hasSetInitial) {
      setHasSetInitial(true);
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: user,
        })
      );
    }
  }, [user, dispatch, storeName, hasSetInitial]);

  const instance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  const saveUpdate = () => {
    setLoading(true);

    dispatch(
      update({
        id: user?.id,
        forceData: {
          first_name: instance?.first_name,
          last_name: instance?.last_name,
          phone: instance?.phone,
          email: instance?.email,
          legal_id: instance?.legal_id,
        },
        successCallback: () => {
          setLoading(false);

          dispatch(getUser(user?.id));

          onCheckout();
        },
        errorCallback: () => {
          setLoading(false);
        },
      })
    );
  };

  return (
    <>
      {loading && <OverlaySpinner />}

      <TextButton
        title="Avbryt och gå tillbaka"
        iconType="arrow-back"
        clicked={() => onCheckout()}
        extraStyle={{ marginBottom: 12 }}
      />

      <TextInput
        extraStyles={{ minWidth: "120px", marginRight: "20px" }}
        storeName={storeName}
        fieldKey={"first_name"}
        method={method}
        title="Förnamn"
      />
      <TextInput
        extraStyles={{ minWidth: "120px" }}
        storeName={storeName}
        fieldKey={"last_name"}
        method={method}
        title="Efternamn"
      />

      <TextInput
        extraStyles={{ minWidth: "120px" }}
        storeName={storeName}
        fieldKey={"email"}
        method={method}
        title="E-post"
      />
      <TextInput
        extraStyles={{ minWidth: "120px" }}
        storeName={storeName}
        fieldKey={"legal_id"}
        method={method}
        title={`${user?.is_company ? "Org-nummer" : "Personnummer"}`}
      />
      <TextInput
        storeName={storeName}
        fieldKey={"phone"}
        method={method}
        title="Telefon"
      />

      <PrimaryButton
        extraStyle={{ marginTop: 12 }}
        title="Uppdatera inställning"
        clicked={saveUpdate}
      />
    </>
  );
}
