import moment from "moment";

export const getComponentsByRooms = ({ components, premisesRooms = [] }) => {
  if (!components?.length && !premisesRooms?.length) return {};
  // structure:
  // roomId: {
  //  ...room,
  //  components: []
  //}
  const rooms = {};

  let i = 0;
  for (i; i < components.length; i++) {
    const current = components[i];

    if (rooms[current.room?.id]) {
      rooms[current.room.id].components.push(current);
    } else if (rooms[`parking-${current.parking_spot?.id}`]) {
      rooms[`parking-${current.parking_spot.id}`].components.push(current);
    } else {
      if (current.parking_spot) {
        rooms[`parking-${current.parking_spot.id}`] = {
          ...current.parking_spot,
          str_representation: "Fordonsplats",
          components: [current],
        };
      } else {
        rooms[current.room.id] = {
          ...current.room,
          components: [current],
        };
      }
    }
  }

  let k = 0;

  for (k; k < premisesRooms.length; k++) {
    const current = premisesRooms[k];

    if (!rooms[current.id]) {
      rooms[current.id] = {
        ...current,
        components: [],
      };
    }
  }

  return rooms;
};

export const getCurrentProductTypeUsage = (component) => {
  if (!component) return null;
  const current = component.product_types?.reduce((acc, cur) => {
    if (!acc) return cur;

    if (moment(cur.from_date).isAfter(moment(acc.from_date))) return cur;

    return acc;
  }, null);

  return current;
};

export const getInspectionErrandChecklistIdForComponent = (component) => {
  const currentProductTypeUsage = getCurrentProductTypeUsage(component);

  if (currentProductTypeUsage?.product_type?.inspection_errand_checklist) {
    return currentProductTypeUsage.product_type.inspection_errand_checklist.id;
  } else if (
    currentProductTypeUsage?.product_type?.component_type
      ?.inspection_errand_checklist
  ) {
    return currentProductTypeUsage?.product_type.component_type
      .inspection_errand_checklist.id;
  }

  return null;
};

export const getReportErrandChecklistIdForComponent = (component) => {
  const currentProductTypeUsage = getCurrentProductTypeUsage(component);

  if (currentProductTypeUsage?.product_type?.report_errand_checklist) {
    return currentProductTypeUsage.product_type.report_errand_checklist.id;
  } else if (
    currentProductTypeUsage?.product_type?.component_type
      ?.report_errand_checklist
  ) {
    return currentProductTypeUsage?.product_type.component_type
      .report_errand_checklist.id;
  }

  return null;
};
