import * as React from "react";
import moment from "moment";
import { useCalendar } from "../store/calendar";
import { useHistory, useParams } from "react-router-dom";
import { axiosInstance } from "../store/base/store/axios";
import { ChapterWrapper } from "./styles";
import { InnerBox } from "../components/sharedStyles";
import {
  ListItem,
  ListItemDate,
  ListItemTitle,
} from "../components/Content/styles";
import PrimaryButton from "../components/Buttons/PrimaryButton";
import Chapter from "../components/Content/Chapter";
import { create as createBooking } from "../store/calendarEvents";

import { cloneDeep } from "lodash";
import OverlaySpinner from "../components/Content/Loaders/OverlaySpinner";
import CalendarTimePicker from "../components/Form/CalendarDatePicker";
import Hero from "../components/Content/Hero";
import PageTitle from "../components/Content/PageTitle";
import { useDispatch } from "react-redux";
import { addToast, TOAST_TYPES } from "../store/toasts";
import useScreenSize from "../hooks/useScreenSize";
import { useReportErrand } from "../store/reportErrands";

const buildUrl = ({ calendarId, query }) =>
  `/events/bokamera/available_times/${calendarId}/?${query}`;

const today = moment().format("YYYY-MM-DD");
const end = moment(today).add(30, "days").format("YYYY-MM-DD");
const endFree = moment(today).add(90, "days").format("YYYY-MM-DD");

export default function Booking() {
  const dispatch = useDispatch();
  const { meetingCalendarId, reportErrandId } = useParams();
  const { push } = useHistory();
  const [selectedTime, setSelectedTime] = React.useState(null);
  const [reportErrand] = useReportErrand(reportErrandId);
  const [bokaMeraTimes, setBokaMeraTimes] = React.useState(null);
  const [bokaMeraTimesLoading, setBokaMeraTimesLoading] = React.useState(false);
  const [nextAvailableTime, setNextAvailableTime] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { isMobile } = useScreenSize();
  const query = `start=${today}T00:00:00&end=${end}T00:00:00&start_free=${today}T00:00:00&end_free=${endFree}T00:00:00`;

  const [calendar] = useCalendar(meetingCalendarId);
  const url = buildUrl({ calendarId: meetingCalendarId, query });

  const onBookingTimeSelected = () => {
    setLoading(true);
    const meetingObj = {
      start: selectedTime?.From,
      end: selectedTime?.To,
      content_type: "errands.reporterrand",
      object_id: reportErrandId,
      calendar: {
        id: meetingCalendarId,
      },
      title: "Videomöte",
      description: `Videomöte om avhälpande av felanmälan ${reportErrand?.errand_id}`,
    };
    dispatch(
      createBooking({
        preventDefaultToast: true,
        forceData: meetingObj,
        successCallback: () => {
          setLoading(false);
          push(`/errands/${reportErrandId}`);

          dispatch(
            addToast({
              type: TOAST_TYPES.SUCCESS,
              title: "Ett möte för avhälpande bokades in",
              description: `Detaljer kring ditt möte hittar du under "Kommande möten" på startsidan.`,
            })
          );
        },
        errorCallback: () => {
          setLoading(false);
          dispatch(
            addToast({
              type: TOAST_TYPES.ERROR,
              title: "Mötesbokningen kunde inte genomföras",
            })
          );
          push(`/errands/${reportErrandId}`);
        },
      })
    );
  };

  const fetchBokaMeraTimes = async () => {
    setBokaMeraTimesLoading(true);

    try {
      const { data } = await axiosInstance.get(url);

      setBokaMeraTimes(data?.available);
      setNextAvailableTime(data?.next_free);

      setBokaMeraTimesLoading(false);
    } catch (e) {
      setError(
        "Lediga tider kan inte hämtas in för tillfället. Du kan ändå gå vidare och skapa din felanmälan så hör vi av oss."
      );
      setBokaMeraTimesLoading(false);
    }
  };

  React.useEffect(() => {
    if (!bokaMeraTimes && !bokaMeraTimesLoading) {
      fetchBokaMeraTimes();
    }
  }, [meetingCalendarId, bokaMeraTimes, bokaMeraTimesLoading]);

  return (
    <>
      {loading && <OverlaySpinner />}
      {!isMobile && <Hero />}

      <PageTitle>Boka tid för digitalt möte</PageTitle>

      {error ? (
        <ChapterWrapper>
          <InnerBox>
            <ListItemTitle>{error}</ListItemTitle>

            <PrimaryButton
              extraStyle={{ marginTop: 12 }}
              title="Gå tillbaka till ärende"
              clicked={() => push(`/errands/${reportErrandId}`)}
            />
          </InnerBox>
        </ChapterWrapper>
      ) : selectedTime ? (
        <ChapterWrapper>
          <Chapter>
            <InnerBox>
              <ListItem>
                <ListItemDate>Bekräfta vald tid för möte</ListItemDate>
                <ListItemTitle>
                  {moment(selectedTime?.From).format("YYYY-MM-DD")}
                </ListItemTitle>
                <ListItemTitle>
                  {moment(selectedTime?.From).format("HH:mm")} -{" "}
                  {moment(selectedTime?.To).format("HH:mm")}
                </ListItemTitle>

                <div style={{ marginTop: 12, display: "flex" }}>
                  <PrimaryButton
                    extraStyle={{ flex: 1 }}
                    title="Bekräfta och boka"
                    clicked={() =>
                      onBookingTimeSelected({
                        selectedTime,
                        calendar: cloneDeep(calendar),
                      })
                    }
                  />
                </div>
              </ListItem>
            </InnerBox>
          </Chapter>
        </ChapterWrapper>
      ) : (
        <ChapterWrapper>
          <Chapter extraStyle={{ padding: 0 }} title="Tillgängliga tider">
            {calendar?.booking_info && (
              <ListItemDate style={{ marginBottom: 6 }}>
                {calendar?.booking_info}
              </ListItemDate>
            )}
            <InnerBox>
              {bokaMeraTimesLoading && <OverlaySpinner />}

              <CalendarTimePicker
                onTimeSelected={(time) => setSelectedTime(time)}
                availableTimes={bokaMeraTimes}
                nextFree={nextAvailableTime}
                availableTimesLoading={bokaMeraTimesLoading}
                calendar={calendar}
              />
            </InnerBox>
          </Chapter>
        </ChapterWrapper>
      )}
    </>
  );
}
