import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useObject } from "../../base";
import {
  getSingle,
  getTenantInvoiceData,
  getTenantInvoicePreview,
} from "../store/actions";
import constants from "../store/constants";

export function useInvoiceConfig(id) {
  const params = {
    storeName: constants.STORE_NAME,
    id: id,
    fetchMethod: getSingle,
  };
  return useObject(params);
}

export default function useTenantInvoiceData() {
  const dispatch = useDispatch();

  const invoiceData = useSelector(
    (state) => state[constants.STORE_NAME].invoiceData
  );

  const invoiceDataLoading = useSelector(
    (state) => state[constants.STORE_NAME].isLoading
  );

  const userLoggedin = useSelector((state) => !!state.app.authorization);

  React.useEffect(() => {
    if (invoiceData === undefined && userLoggedin && !invoiceDataLoading) {
      dispatch(getTenantInvoiceData());
    }
  }, [invoiceData, userLoggedin, invoiceDataLoading, dispatch]);

  return [invoiceData, invoiceDataLoading];
}

export const useTenantPreviewInvoiceData = (actionId) => {
  const dispatch = useDispatch();

  const invoicePreviewData = useSelector(
    (state) => state[constants.STORE_NAME]?.[`preview_${actionId}`]
  );
  const loading = useSelector(
    (state) => state[constants.STORE_NAME][`loading_${actionId}`]
  );

  const userLoggedin = useSelector((state) => !!state.app.authorization);

  React.useEffect(() => {
    if (userLoggedin && !loading) {
      if (!invoicePreviewData) {
        dispatch(getTenantInvoicePreview(actionId));
      }
    }
  }, [dispatch, userLoggedin, actionId, invoicePreviewData, loading]);

  return [invoicePreviewData, loading];
};
