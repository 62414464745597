import * as React from "react";
import moment from "moment";
import { useCalendar } from "../../../store/calendar";
import { axiosInstance } from "../../../store/base/store/axios";
import OverlaySpinner from "../Loaders/OverlaySpinner";
import CalendarTimePicker from "../../Form/CalendarDatePicker";
import { InnerBox } from "../../sharedStyles";
import { ChapterWrapper } from "../../../views/styles";
import Chapter from "../Chapter";
import { ListItem, ListItemDate, ListItemTitle } from "../styles";
import PrimaryButton from "../../Buttons/PrimaryButton";
import { cloneDeep } from "lodash";

const buildUrl = ({ calendarId, query }) =>
  `/events/bokamera/available_times/${calendarId}/?${query}`;

const today = moment().format("YYYY-MM-DD");
const end = moment(today).add(30, "days").format("YYYY-MM-DD");
const endFree = moment(today).add(90, "days").format("YYYY-MM-DD");

export default function Booking({ meetingCalendarId, onBookingTimeSelected }) {
  const [selectedTime, setSelectedTime] = React.useState(null);
  const [bokaMeraTimes, setBokaMeraTimes] = React.useState(null);
  const [bokaMeraTimesLoading, setBokaMeraTimesLoading] = React.useState(false);
  const [nextAvailableTime, setNextAvailableTime] = React.useState(null);
  const [error, setError] = React.useState(false);

  const query = `start=${today}T00:00:00&end=${end}T00:00:00&start_free=${today}T00:00:00&end_free=${endFree}T00:00:00`;

  const [calendar] = useCalendar(meetingCalendarId);
  const url = buildUrl({ calendarId: meetingCalendarId, query });

  const fetchBokaMeraTimes = async () => {
    setBokaMeraTimesLoading(true);

    try {
      const { data } = await axiosInstance.get(url);

      setBokaMeraTimes(data?.available);
      setNextAvailableTime(data?.next_free);

      setBokaMeraTimesLoading(false);
    } catch (e) {
      setError(
        "Lediga tider kan inte hämtas in för tillfället. Du kan ändå gå vidare och skapa din felanmälan så hör vi av oss."
      );
      setBokaMeraTimesLoading(false);
    }
  };

  React.useEffect(() => {
    if (!bokaMeraTimes && !bokaMeraTimesLoading) {
      fetchBokaMeraTimes();
    }
  }, [meetingCalendarId, bokaMeraTimes, bokaMeraTimesLoading]);

  if (error) {
    return (
      <ChapterWrapper>
        <InnerBox>
          <ListItemTitle>{error}</ListItemTitle>

          <PrimaryButton
            extraStyle={{ marginTop: 12 }}
            title="Gå vidare till felanmälan"
            clicked={() => onBookingTimeSelected()}
          />
        </InnerBox>
      </ChapterWrapper>
    );
  }

  if (selectedTime) {
    return (
      <ChapterWrapper>
        <Chapter>
          <InnerBox>
            <ListItem>
              <ListItemDate>Bekräfta vald tid för möte</ListItemDate>
              <ListItemTitle>
                {moment(selectedTime?.From).format("YYYY-MM-DD")}
              </ListItemTitle>
              <ListItemTitle>
                {moment(selectedTime?.From).format("HH:mm")} -{" "}
                {moment(selectedTime?.To).format("HH:mm")}
              </ListItemTitle>

              <div style={{ marginTop: 12, display: "flex" }}>
                <PrimaryButton
                  extraStyle={{ flex: 1 }}
                  title="Bekräfta och gå vidare"
                  clicked={() =>
                    onBookingTimeSelected({
                      selectedTime,
                      calendar: cloneDeep(calendar),
                    })
                  }
                />
              </div>
            </ListItem>
          </InnerBox>
        </Chapter>
      </ChapterWrapper>
    );
  }

  return (
    <>
      <ChapterWrapper>
        <Chapter extraStyle={{ padding: 0 }} title="Tillgängliga tider">
          {calendar?.booking_info && (
            <ListItemDate style={{ marginBottom: 6 }}>
              {calendar?.booking_info}
            </ListItemDate>
          )}
          <InnerBox>
            {bokaMeraTimesLoading && <OverlaySpinner />}

            <CalendarTimePicker
              onTimeSelected={(time) => setSelectedTime(time)}
              availableTimes={bokaMeraTimes}
              nextFree={nextAvailableTime}
              availableTimesLoading={bokaMeraTimesLoading}
              calendar={calendar}
            />
          </InnerBox>
        </Chapter>
      </ChapterWrapper>
    </>
  );
}
