export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_BRF_PREMISES",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_BRF_PREMISES",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_BRF_PREMISES",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_BRF_PREMISES",
  DESTROY_FORM: "DESTROY_FORM_BRF_PREMISES",
  SET_FORM_ERROR: "SET_FORM_ERROR_BRF_PREMISES",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_BRF_PREMISES",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_BRF_PREMISES",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_BRF_PREMISES",
  RESET_STATE: "RESET_STATE_BRF_PREMISES",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_BRF_PREMISES",
  REMOVE_OBJECT: "REMOVE_OBJECT_BRF_PREMISES",
  CLEAR_FETCHED: "CLEAR_FETCHED_BRF_PREMISES",

  SET_BROKER_IMAGE: "SET_BROKER_IMAGE_BRF_PREMISES",

  LIST_URL: "/brf/brfpremis/list/",
  POST_URL: "/brf/brfpremis/",
  PATCH_URL: "/brf/brfpremis/",
  GET_URL: "/brf/brfpremis/",
  BROKER_DOC_URL: "/pdfgenerator/brf/broker_document/broker_document/",

  STORE_NAME: "brfPremises",
};
