import { axiosInstance } from "../../base/store/axios";
import constants from "./constants";

async function markNotificationsAsRead(readList) {
  try {
    const { data } = await axiosInstance.post(
      `events/notifications/mark_read/`,
      {
        notification_ids: readList,
      }
    );

    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getNotificationObject({ content_type, object_id }) {
  try {
    const { data } = await axiosInstance.get(constants.GET_OBJECT_URL, {
      params: { content_type, object_id },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function toggleNotificationObject({ content_type, object_id }) {
  try {
    const { data } = await axiosInstance.put(constants.PUT_OBJECT_URL, {
      content_type,
      object_id,
    });
    return data;
  } catch (error) {
    console.log(error);
  }
}

export {
  markNotificationsAsRead,
  getNotificationObject,
  toggleNotificationObject,
};
