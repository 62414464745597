import {
  buildQueryString,
  useFilteredObjects,
  useObject,
  usePagination,
} from "../../base";
import { usePaginationCount } from "../../base/hooks/retrieve";
import { filterPagination, performFilter, getSingle } from "../store/actions";
import constants from "../store/constants";

export function useNotificationSetting() {
  const params = {
    storeName: constants.STORE_NAME,
    id: constants.SETTING_STATE_ID,
    fetchMethod: getSingle,
  };
  return useObject(params);
}

export function useFilteredNotifications(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: performFilter,
    querystring: querystring,
  };

  return useFilteredObjects(params);
}

export function useNotificationsPagination(querystring) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: querystring,
  };

  return usePagination(params);
}

export function useNotificationsPaginationCount({ filters = {} }) {
  const queryString = buildQueryString({
    _page: 1,
    _page_size: 0,
    ...filters,
  });

  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: filterPagination,
    querystring: queryString,
  };

  return usePaginationCount(params);
}
