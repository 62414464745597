export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_NOTIFICATIONS",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_NOTIFICATIONS",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_NOTIFICATIONS",
  DESTROY_FORM: "DESTROY_FORM_NOTIFICATIONS",
  SET_FORM_ERROR: "SET_FORM_ERROR_NOTIFICATIONS",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_NOTIFICATIONS",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_NOTIFICATIONS",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_NOTIFICATIONS",
  INSERT_INTO_NOTIF_OBJECTS: "INSERT_INTO_NOTIF_OBJECTS",
  RESET_STATE: "RESET_STATE_NOTIFICATIONS",

  SETTING_STATE_ID: "AUTHED_NOTIF_SETTING",

  LIST_URL: "/events/notification/list/",
  GET_URL: "/events/notification/",
  POST_URL: "/events/notification/",

  PATCH_CONFIG_URL: "/accounts/notifications/notification_setting/",
  GET_CONFIG_URL: "/accounts/notifications/notification_setting/",
  GET_OBJECT_URL:
    "/accounts/notifications/notification_setting/instance_block/",
  PUT_OBJECT_URL:
    "/accounts/notifications/notification_setting/instance_block/",

  STORE_NAME: "notifications",
};
