import { InnerBox, OverviewTitle, OverviewTitleWrapper } from "../sharedStyles";
import * as SC from "./styles";

export default function YesNoButton({ title, onYes, onNo }) {
  return (
    <InnerBox>
      {title && (
        <OverviewTitleWrapper style={{ justifyContent: "center" }}>
          <OverviewTitle style={{ fontSize: 14 }}>{title}</OverviewTitle>
        </OverviewTitleWrapper>
      )}
      <SC.YesNoWrapper>
        <SC.YesButton onClick={onYes}>Ja</SC.YesButton>
        <SC.NoButton onClick={onNo}>Nej</SC.NoButton>
      </SC.YesNoWrapper>
    </InnerBox>
  );
}
