import { useObject, useObjects, usePagination, useFilteredObjects } from "../../base";
import { getAll, performFilter, filterPagination, getSingle } from "../store/actions"
import constants from "../store/constants"

export function useSendout(id) {
    const params = {
        storeName:constants.STORE_NAME,
        id:id,
        fetchMethod: getSingle
    }
    return useObject(params);
}

export function useSendouts(filterMethod) {
    const params = {
        storeName:constants.STORE_NAME,
        filterMethod: filterMethod,
        fetchMethod: getAll
    }
    return useObjects(params);
}

export function useFilteredSendouts(querystring) {
    const params = {
        storeName:constants.STORE_NAME,
        fetchMethod: performFilter,
        querystring: querystring
    }

    return useFilteredObjects(params);
}

export function useSendoutsPagination(querystring) {
    const params = {
        storeName:constants.STORE_NAME,
        fetchMethod: filterPagination,
        querystring: querystring
    }

    return usePagination(params);
}