import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { constants, getAnonCompanyData } from "../store/portalSettings";

export default function useAnonCompanyData() {
  const { companyKey } = useParams();
  const dispatch = useDispatch();
  const anonCompanyData = useSelector(
    (state) => state[constants.STORE_NAME].anonCompanyData
  );
  const loading = useSelector(
    (state) => state[constants.STORE_NAME].isAnonLoading
  );

  React.useEffect(() => {
    if (companyKey && anonCompanyData === undefined && !loading) {
      dispatch(getAnonCompanyData(companyKey));
    }
  }, [dispatch, companyKey, anonCompanyData, loading]);

  return [anonCompanyData, loading];
}
