import { useReportErrandForm } from "../../../store/reportErrands";
import ImageM2MContent from "../../Form/ImageM2MContent";
import Chapter from "../Chapter";

import { constants } from "../../../store/reportErrands";
import { InnerBox } from "../../sharedStyles";
import TextField from "../../Form/TextField";
import TextInput from "../../Form/TextInput";
import PrimaryButton from "../../Buttons/PrimaryButton";
import { useFormInstanceField } from "../../../store/base";
import { ChapterWrapper } from "../../../views/styles";
import Checkbox from "../../Form/Checkbox";

export default function Description({ onCreate, loading }) {
  const storeName = constants.STORE_NAME;
  const method = "POST";

  const instance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  useReportErrandForm(method);

  const disabled =
    !instance?.title || instance?.report_comments?.[0]?.length < 1 || loading;

  return (
    <ChapterWrapper>
      <Chapter title="Beskriv felet">
        <InnerBox style={{ maxWidth: 450 }}>
          <TextInput
            storeName={storeName}
            fieldKey={"title"}
            method={method}
            title="Titel"
            manualInstructions={{ _required: true }}
            description="Ange en titel för felet, felbeskrivning gör du i fältet under."
            extraStyles={{ minWidth: "120px" }}
          />
          <TextField
            storeName={storeName}
            fieldKey={`report_comments[0].content`}
            instructionsKey={"report_comments.content"}
            title="Felbeskrivning"
            manualInstructions={{ _required: true }}
            description="Ange så detaljerat som möjligt vad ärendet handlar om och vad felet är."
            method={method}
            extraStyles={{ minWidth: "120px" }}
          />
          <ImageM2MContent
            storeName={storeName}
            fieldKey={"report_files"}
            method={method}
            title="Bilder på felet"
            manualInstructions={{}}
            fileKey={"file"}
            allowedFileFormats={[".png", ".jpg", ".pdf"]}
            contentKey={"description"}
          />

          <Checkbox
            storeName={storeName}
            fieldKey={"can_use_key"}
            manualInstructions={{ _required: true }}
            method={method}
            title="Jag vidtar åtgärder som krävs för att utförare ska få tillträde till lägenheten eller lokalen, exempelvis befinner mig i lägenheten, ställer dörr i serviceläge, lämnar nyckel i tub, tillåter tillträde med huvudnyckel."
          />

          <PrimaryButton
            disabled={disabled}
            extraStyle={{ marginTop: 24 }}
            title={"Skapa felanmälan"}
            clicked={onCreate}
          />
        </InnerBox>
      </Chapter>
    </ChapterWrapper>
  );
}
