export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_INDUSTRIAL_PREMISES",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_INDUSTRIAL_PREMISES",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_PREMISES",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_INDUSTRIAL_PREMISES",
  DESTROY_FORM: "DESTROY_FORM_INDUSTRIAL_PREMISES",
  SET_FORM_ERROR: "SET_FORM_ERROR_INDUSTRIAL_PREMISES",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_INDUSTRIAL_PREMISES",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_INDUSTRIAL_PREMISES",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_INDUSTRIAL_PREMISES",
  RESET_STATE: "RESET_STATE_INDUSTRIAL_PREMISES",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_INDUSTRIAL_PREMISES",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_PREMISES",
  CLEAR_FETCHED: "CLEAR_FETCHED_INDUSTRIAL_PREMISES",

  LIST_URL: "/standard/mainlevel/premises/industrialpremises/list/",
  POST_URL: "/standard/mainlevel/premises/industrialpremises/",
  PATCH_URL: "/standard/mainlevel/premises/industrialpremises/",
  GET_URL: "/standard/mainlevel/premises/industrialpremises/",

  STORE_NAME: "industrialPremises",
};
