import * as React from "react";
import { getCurrentProductTypeUsage } from "../../../store/errandComponents/utils";
import { ChapterWrapper } from "../../../views/styles";
import { InnerBox } from "../../sharedStyles";
import Chapter from "../Chapter";
import {
  ListItem,
  ListItemDate,
  ListItemTitle,
  ListItemWrapper,
} from "../styles";

export default function SelectComponent({ selectedRoom, selectComponent }) {
  return (
    <ChapterWrapper>
      <Chapter
        extraStyle={{ padding: 0, maxWidth: 450 }}
        title={`Komponenter i ${
          selectedRoom?.room_id || selectedRoom?.str_representation
        }`}
      >
        <ListItemWrapper>
          {selectedRoom?.components?.map((comp) => {
            const currentProductUsage = getCurrentProductTypeUsage(comp);
            const productType = currentProductUsage?.product_type;
            const componentType = productType?.component_type;

            return (
              <ListItem onClick={() => selectComponent(comp)} key={comp.id}>
                <ListItemTitle>
                  {componentType?.str_representation || "Okänd"}
                </ListItemTitle>
                <ListItemDate>
                  {productType?.str_representation || "Saknar komponentmodell"}
                </ListItemDate>
              </ListItem>
            );
          })}
        </ListItemWrapper>
      </Chapter>
    </ChapterWrapper>
  );
}
