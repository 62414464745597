import * as React from "react";
import { InnerBox } from "../sharedStyles";
import * as SC from "./styles";

export default function MediaDisplay({
  files = [],
  fileKey = "file",
  noName = false,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        borderRadius: 12,
      }}
    >
      {files?.map((file) => {
        return (
          <SC.FileDisplay
            style={{ cursor: "pointer" }}
            onClick={() => window.open(file[fileKey]?.get, "_blank").focus()}
            key={file.id}
            id={file.id}
            src={file[fileKey]?.get}
            name={file.str_representation}
          >
            {!noName && (
              <SC.FileDisplayName>{file.str_representation}</SC.FileDisplayName>
            )}
          </SC.FileDisplay>
        );
      })}

      {files?.length === 0 && <InnerBox>Filer saknas</InnerBox>}
    </div>
  );
}
