import constants from "./constants";
import * as services from "./services";
import { get, post, patch, options, destroyForm } from "../../base";
import { getSingleFetchProcessName } from "../../base";

export const getTenantInvoiceData = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: constants.SET_LOADING,
      });
      const data = await services.getTenantInvoiceData();

      data.InvoiceActions.sort((a, b) => {
        return a.InvoiceDate > b.InvoiceDate ? -1 : 1;
      });

      dispatch({
        type: constants.SET_INVOICE_DATA,
        payload: { data: data || null },
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getTenantInvoicePreview = (actionId) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_PREVIEW_LOADING,
      payload: { actionId },
    });
    const data = await services.getTenantInvoicePreview(actionId);
    dispatch({
      type: constants.SET_INVOICE_PREVIEW_DATA,
      payload: { data, actionId },
    });
  };
};

export const initiateSwishPayment = (actionId, phoneNumber) => {
  return async (dispatch) => {
    dispatch({
      type: constants.SET_SWISH_LOADING,
    });
    const data = await services.initiateSwishPayment(actionId, phoneNumber);

    dispatch({
      type: constants.SET_SWISH_DONE,
      payload: { data },
    });
  };
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

export const create = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
}) => {
  return post({
    url: constants.POST_URL,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
    preProcess,
  });
};

export const update = ({
  id,
  preProcess,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
}) => {
  const url = `${constants.PATCH_URL}${id}`;

  return patch({
    url,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
    preProcess,
  });
};
