import { useHistory } from "react-router-dom";
import Chapter from "../components/Content/Chapter";
import Hero from "../components/Content/Hero";
import InspectionErrandItem from "../components/Content/InspectionErrandItem";
import PageTitle from "../components/Content/PageTitle";
import ReportErrandItem from "../components/Content/ReportErrandItem";
import {
  ListItem,
  ListItemTitle,
  ListItemWrapper,
} from "../components/Content/styles";
import { InnerBox } from "../components/sharedStyles";
import useCompanySetting from "../hooks/useCompanySetting";
import useScreenSize from "../hooks/useScreenSize";
import { buildQueryString } from "../store/base";
import { useFilteredInspectionErrands } from "../store/inspectionErrands";
import { ChapterWrapper } from "./styles";

export default function Inspections() {
  const [setting] = useCompanySetting();
  const { push } = useHistory();
  const { isMobile } = useScreenSize();

  const [inspectionErrands, inspecitonErrandsLoading] =
    useFilteredInspectionErrands("");

  return (
    <>
      {!isMobile && <Hero />}

      <PageTitle>Besiktningar</PageTitle>

      <ChapterWrapper>
        <Chapter title="Kommande besiktningar">
          <ListItemWrapper>
            {inspectionErrands?.map((errand) => (
              <InspectionErrandItem errand={errand} key={errand.id} />
            ))}

            {inspectionErrands?.length === 0 && (
              <ListItem>
                <ListItemTitle>Inga kommande besiktningar</ListItemTitle>
              </ListItem>
            )}
          </ListItemWrapper>
        </Chapter>
      </ChapterWrapper>
    </>
  );
}
