export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_EDITABLEDOC",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_EDITABLEDOC",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_EDITABLEDOC",
  DESTROY_FORM: "DESTROY_FORM_EDITABLEDOC",
  SET_FORM_ERROR: "SET_FORM_ERROR_EDITABLEDOC",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_EDITABLEDOC",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_EDITABLEDOC",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_EDITABLEDOC",
  RESET_STATE: "RESET_STATE_EDITABLEDOC",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_EDITABLEDOC",
  REMOVE_OBJECT: "REMOVE_OBJECT_EDITABLEDOC",
  INSERT_INTO_HISTORY: "INSERT_INTO_HISTORY_EDITABLEDOC",
  SET_HAS_SIGNED_UP: "SET_HAS_SIGNED_UP_EDITABLEDOC",
  CLEAR_FETCHED: "CLEAR_FETCHED_EDITABLEDOC",

  LIST_URL: "/standard/doceditor/editabledoc/list/",
  GET_URL: "/standard/doceditor/editabledoc/",
  PATCH_URL: "/standard/doceditor/editabledoc/",
  POST_URL: "/standard/doceditor/editabledoc/",
  PUBLIC_TEMPLATE_URL: "/standard/doceditor/editabledocs/public_templates/",
  FASTDOC_TEMPLATE_URL: "/external/fastdoc/templates/",
  FASTDOC_HANDLE_URL: "/external/fastdoc/template_fetch/",
  AUTH_URL: "/external/fastdoc/authenticate/",

  FASTDOC_INSTANCE_QS_CONSTANT: "instance",
  FASTDOC_TEMPLATE_QS_CONSTANT: "template",

  STORE_NAME: "editabledocs",
};
