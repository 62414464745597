import * as React from "react";
import * as SC from "./styles";

import homeIcon from "../../assets/svg/home_black.svg";
import paymentLogo from "../../assets/svg/payments_black.svg";
import contractLogo from "../../assets/svg/contract_black.svg";
import apartmentLogo from "../../assets/svg/apartment_black.svg";
import infoLogo from "../../assets/svg/info_black.svg";
import lockLogo from "../../assets/svg/lock_red.svg";
import inspectionLogo from "../../assets/svg/inspection_black.svg";
import workerLogo from "../../assets/svg/worker_black.svg";

import theme from "../../theme";
import { CustomerLogo } from "../Content/styles";
import { useDispatch } from "react-redux";
import { logout } from "../../store/app/actions";
import useCompanySetting from "../../hooks/useCompanySetting";
import { useLocation } from "react-router-dom";
import { useLeaseContractPaginationCount } from "../../store/leaseContracts/hooks/retrieve";
import { useOtherContractPaginationCount } from "../../store/otherContracts/hooks/retrieve";
import { useParkingContractPaginationCount } from "../../store/parkingContracts/hooks/retrieve";
import { useInspectionErrandsPaginationCount } from "../../store/inspectionErrands";

export default function DesktopMenu() {
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const [setting, settingLoading] = useCompanySetting();

  const hideErrands = setting?.hide_errand_handling;

  const [leaseContractCount] = useLeaseContractPaginationCount({});
  const [otherContractCount] = useOtherContractPaginationCount({});
  const [parkingContractCount] = useParkingContractPaginationCount({});
  const [inspectionsCount] = useInspectionErrandsPaginationCount({});

  const hasContracts =
    leaseContractCount > 0 ||
    otherContractCount > 0 ||
    parkingContractCount > 0;

  const hasInspections = inspectionsCount > 0;

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <SC.DekstopMenuWrapper>
      <CustomerLogo url={setting?.logo?.get} />

      <SC.MenuItem active={pathname === "/"} to="/" icon={homeIcon}>
        Hem
      </SC.MenuItem>

      {!hideErrands && (
        <>
          {" "}
          <SC.MenuItem
            active={pathname.includes("/errands")}
            to="/errands"
            icon={workerLogo}
          >
            Felanmälan
          </SC.MenuItem>
          {hasInspections && (
            <SC.MenuItem
              active={pathname.includes("/inspections")}
              to="/inspections"
              icon={inspectionLogo}
            >
              Besiktningar
            </SC.MenuItem>
          )}
        </>
      )}
      <SC.MenuItem
        active={pathname.includes("/invoices")}
        to="/invoices"
        icon={paymentLogo}
      >
        Avier och betalningar
      </SC.MenuItem>
      <SC.MenuItem
        active={pathname.includes("/objects")}
        to="/objects"
        icon={apartmentLogo}
      >
        Mina objekt
      </SC.MenuItem>
      {hasContracts && (
        <SC.MenuItem
          active={pathname.includes("/contracts")}
          to="/contracts"
          icon={contractLogo}
        >
          Mina avtal
        </SC.MenuItem>
      )}
      <SC.MenuItem
        active={pathname.includes("/documents-and-info")}
        to="/documents-and-info"
        icon={infoLogo}
      >
        Dokument och info
      </SC.MenuItem>
      {/* <SC.MenuItem
        active={pathname.includes("/bookings")}
        to="/bookings"
        icon={watchLogo}
      >
        Bokningar
      </SC.MenuItem>
      <SC.MenuItem
        active={pathname.includes("/rentals")}
        to="/rentals"
        icon={secondHandBlackLogo}
      >
        Andrahandsuthyrning
      </SC.MenuItem>
      <SC.MenuItem
        active={pathname.includes("/brfcompany")}
        to="/brfcompany"
        icon={gavelLogo}
      >
        Min förening
      </SC.MenuItem> */}
      <SC.MenuItem
        onClick={handleLogout}
        style={{ color: theme(setting?.color_code).colors.red, marginTop: 40 }}
        icon={lockLogo}
      >
        Logga ut
      </SC.MenuItem>
    </SC.DekstopMenuWrapper>
  );
}
