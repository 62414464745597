import * as React from "react";
import { useHistory } from "react-router-dom";
import TextButton from "../components/Buttons/TextButton";
import Hero from "../components/Content/Hero";
import OverlaySpinner from "../components/Content/Loaders/OverlaySpinner";
import Notification from "../components/Content/Notification";
import PageTitle from "../components/Content/PageTitle";
import { ListItemWrapper } from "../components/Content/styles";
import { InnerBox } from "../components/sharedStyles";
import useScreenSize from "../hooks/useScreenSize";
import { buildQueryString } from "../store/base";
import { useNotificationsPagination } from "../store/notifications";

export default function Notifications() {
  const [page, setPage] = React.useState(1);
  const [hasNextPage, setHasNextPage] = React.useState(false);
  const { isMobile } = useScreenSize();
  const pageSize = 10;

  const query = buildQueryString({
    order_by: ["-created_at", "-read_by_me"],
    _page: page,
    _page_size: pageSize,
  });

  const [notifications, notificationsLoading] =
    useNotificationsPagination(query);

  React.useEffect(() => {
    if (!!notifications?.next != hasNextPage) {
      setHasNextPage(!!notifications?.next);
    }
  }, [notifications?.next]);

  return (
    <>
      {notificationsLoading && <OverlaySpinner />}
      {!isMobile && <Hero />}

      <PageTitle>Notifikationer</PageTitle>

      <div style={{ padding: "0 12px 12px 12px" }}>
        <InnerBox>
          <ListItemWrapper>
            {notifications?.results?.map((n) => (
              <Notification key={n.id} notification={n} />
            ))}
          </ListItemWrapper>

          <div style={{ display: "flex", alignItems: "center" }}>
            {page > 1 && (
              <TextButton
                extraStyle={{ marginTop: 12 }}
                title="Visa nyare notiser"
                iconType="arrow-back"
                clicked={() => setPage(page - 1)}
              />
            )}
            {hasNextPage && (
              <TextButton
                extraStyle={{
                  marginTop: 12,
                  marginRight: 0,
                  marginLeft: "auto",
                }}
                title="Visa äldre notiser"
                iconType="arrow"
                iconPlacement="right"
                clicked={() => setPage(page + 1)}
              />
            )}
          </div>
        </InnerBox>
      </div>
    </>
  );
}
