import * as React from "react";
import * as SC from "./styles";

export default function Chapter({ title, children, extraStyle = {} }) {
  return (
    <SC.Chapter style={extraStyle}>
      {title && <SC.Title>{title}</SC.Title>}
      <hr style={{ margin: ".2rem 0 .5rem 0", height: 2 }} />
      {children}
    </SC.Chapter>
  );
}
