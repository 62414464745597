import * as React from "react";
import moment from "moment";
import Hero from "../components/Content/Hero";
import * as SC from "../components/Content/styles";
import PageTitle from "../components/Content/PageTitle";
import { ListItemWrapper } from "../components/Content/styles";
import { InnerBox } from "../components/sharedStyles";
import { buildQueryString } from "../store/base";
import { useSendoutsPagination } from "../store/sendout";
import { useHistory } from "react-router-dom";
import TextButton from "../components/Buttons/TextButton";
import useScreenSize from "../hooks/useScreenSize";

export default function News() {
  const [page, setPage] = React.useState(1);
  const [hasNextPage, setHasNextPage] = React.useState(false);
  const { isMobile } = useScreenSize();

  const { push } = useHistory();

  const pageSize = 10;

  const query = buildQueryString({
    order_by: ["-pinned", "-scheduled_for", "-sent_at"],
    _page: page,
    _page_size: pageSize,
  });

  const [sendouts, sendoutsLoading] = useSendoutsPagination(query);

  React.useEffect(() => {
    if (!!sendouts?.next != hasNextPage) {
      setHasNextPage(!!sendouts?.next);
    }
  }, [sendouts?.next]);

  return (
    <>
      {!isMobile && <Hero />}
      <PageTitle>Nyheter</PageTitle>

      <div style={{ padding: "0 12px 12px 12px" }}>
        <InnerBox>
          <ListItemWrapper>
            {sendouts?.results?.map((s) => (
              <SC.NewsItem onClick={() => push(`/news/${s.id}`)} key={s.id}>
                <SC.NewsDate>
                  {s.pinned
                    ? "Fäst utskick"
                    : moment(s.scheduled_for).format("YYYY-MM-DD")}{" "}
                </SC.NewsDate>
                <SC.NewsTitle>{s.title}</SC.NewsTitle>
              </SC.NewsItem>
            ))}
          </ListItemWrapper>

          <div style={{ display: "flex", alignItems: "center" }}>
            {page > 1 && (
              <TextButton
                extraStyle={{ marginTop: 12 }}
                title="Visa nyare nyheter"
                iconType="arrow-back"
                clicked={() => setPage(page - 1)}
              />
            )}
            {hasNextPage && (
              <TextButton
                extraStyle={{
                  marginTop: 12,
                  marginRight: 0,
                  marginLeft: "auto",
                }}
                title="Visa äldre nyheter"
                iconType="arrow"
                iconPlacement="right"
                clicked={() => setPage(page + 1)}
              />
            )}
          </div>
        </InnerBox>
      </div>
    </>
  );
}
