import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";

import {
  constants as apartmentConstants,
  reducer as apartmentReducer,
} from "./apartments";
import appReducer from "./app/reducer";
import {
  constants as brfPremisesConstants,
  reducer as brfPremisesReducer,
} from "./brfPremises";
import {
  constants as calendarConstants,
  reducer as calendarReducer,
} from "./calendar";
import {
  constants as calendarEventConstants,
  reducer as calendarEventReducer,
} from "./calendarEvents";
import {
  constants as checklistRowsConstants,
  reducer as checklistRowsReducer,
} from "./checklistRows";
import {
  constants as commonAreaConstants,
  reducer as commonAreaReducer,
} from "./commonAreas";
import {
  constants as editableDocConstants,
  reducer as editableDocReducer,
} from "./editabledocs";
import {
  constants as componentsConstants,
  reducer as componentsReducer,
} from "./errandComponents";
import {
  constants as industrialPremisesConstants,
  reducer as industrialPremisesReducer,
} from "./industrialPremises";
import {
  constants as inspectionErrandConstants,
  reducer as inspectionErrandReducer,
} from "./inspectionErrands";
import {
  constants as leaseContractConstants,
  reducer as leaseContractReducer,
} from "./leaseContracts";
import { constants as notesConstants, reducer as notesReducer } from "./notes";
import {
  constants as notificationsConstants,
  reducer as notificationsReducer,
} from "./notifications";
import {
  constants as otherContractConstants,
  reducer as otherContractReducer,
} from "./otherContracts";
import {
  constants as parkingContractConstants,
  reducer as parkingContractReducer,
} from "./parkingContracts";
import {
  constants as parkingSpotConstants,
  reducer as parkingSpotReducer,
} from "./parkingSpots";
import portalSettingConstants from "./portalSettings/store/constants";
import portalSettingReducer from "./portalSettings/store/reducer";
import {
  constants as reportErrandSettingsConstants,
  reducer as reportErrandSettingsReducer,
} from "./reportErrandSettings";
import {
  constants as reportErrandConstants,
  reducer as reportErrandReducer,
} from "./reportErrands";
import {
  constants as sendoutsConstants,
  reducer as sendoutsReducer,
} from "./sendout";
import {
  constants as tenantInvoicingConstants,
  reducer as tenantInvoicingReducer,
} from "./tenantInvoicing";
import {
  constants as tenantConstants,
  reducer as tenantReducer,
} from "./tenants";
import { constants as toastConstants, reducer as toastReducer } from "./toasts";
import { constants as userConstants, reducer as userReducer } from "./users";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    [userConstants.STORE_NAME]: userReducer,
    [portalSettingConstants.STORE_NAME]: portalSettingReducer,
    [sendoutsConstants.STORE_NAME]: sendoutsReducer,
    [notificationsConstants.STORE_NAME]: notificationsReducer,
    [toastConstants.STORE_NAME]: toastReducer,
    [leaseContractConstants.STORE_NAME]: leaseContractReducer,
    [parkingContractConstants.STORE_NAME]: parkingContractReducer,
    [otherContractConstants.STORE_NAME]: otherContractReducer,
    [apartmentConstants.STORE_NAME]: apartmentReducer,
    [brfPremisesConstants.STORE_NAME]: brfPremisesReducer,
    [commonAreaConstants.STORE_NAME]: commonAreaReducer,
    [industrialPremisesConstants.STORE_NAME]: industrialPremisesReducer,
    [parkingSpotConstants.STORE_NAME]: parkingSpotReducer,
    [componentsConstants.STORE_NAME]: componentsReducer,
    [checklistRowsConstants.STORE_NAME]: checklistRowsReducer,
    [reportErrandSettingsConstants.STORE_NAME]: reportErrandSettingsReducer,
    [reportErrandConstants.STORE_NAME]: reportErrandReducer,
    [inspectionErrandConstants.STORE_NAME]: inspectionErrandReducer,
    [notesConstants.STORE_NAME]: notesReducer,
    [editableDocConstants.STORE_NAME]: editableDocReducer,
    [tenantConstants.STORE_NAME]: tenantReducer,
    [tenantInvoicingConstants.STORE_NAME]: tenantInvoicingReducer,
    [calendarConstants.STORE_NAME]: calendarReducer,
    [calendarEventConstants.STORE_NAME]: calendarEventReducer,
  });

export default createRootReducer;
