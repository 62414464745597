import { useFilteredObjects, useObject } from "../../base";
import { getSingle, performFilter } from "../store/actions";
import constants from "../store/constants";

export function useTenantPortalSetting(id, skipLogout) {
  const params = {
    storeName: constants.STORE_NAME,
    id: id,
    skipLogout,
    fetchMethod: getSingle,
  };
  return useObject(params);
}

export function useFilteredTenantPortalSettings(
  querystring,
  skipLogout = true
) {
  const params = {
    storeName: constants.STORE_NAME,
    fetchMethod: performFilter,
    querystring: querystring,
    skipLogout,
  };
  return useFilteredObjects(params);
}
