import moment from "moment";
import React from "react";
import TextButton from "../Buttons/TextButton";
import InfoItem from "../Content/InfoItem";
import {
  ListItem,
  ListItemTitle,
  ListItemWrapper,
  SplitListItemWrapper,
} from "../Content/styles";
import {
  InnerBox,
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
} from "../sharedStyles";
import NonConnectedDatePicker from "./NonConnectedDatePicker";

import * as SC from "./styles";

export default function CalendarTimePicker({
  onTimeSelected,
  availableTimes,
  nextFree,
  availableTimesLoading,
  calendar,
}) {
  const actualAvailableTimes = availableTimes?.filter((t) => t.Free > 0);
  const [pickedDate, setPickedDate] = React.useState(null);

  const renderTimes = () => {
    const dateTimes = actualAvailableTimes.filter((time) => {
      let date = moment(time?.From).format("YYYY-MM-DD");
      if (date === pickedDate && time?.Free !== 0) return time;
    });
    return dateTimes.map((time) => {
      return (
        <ListItem
          style={{
            borderRadius: 24,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          key={time?.From}
          onClick={() => onTimeSelected(time)}
        >
          <ListItemTitle>
            {moment(time?.From).format("HH:mm")} -{" "}
            {moment(time?.To).format("HH:mm")}
          </ListItemTitle>
        </ListItem>
      );
    });
  };

  const renderNextFree = () => {
    return (
      <ListItem
        style={{ marginBottom: 12 }}
        onClick={() => onTimeSelected(nextFree?.[0])}
      >
        <ListItemTitle>Nästa lediga tid</ListItemTitle>
        <ListItemTitle>
          {moment(nextFree?.[0]?.From).format("YYYY-MM-DD HH:mm")} -{" "}
          {moment(nextFree?.[0]?.To).format("HH:mm")}
        </ListItemTitle>

        <ListItemTitle>
          <TextButton
            title="Välj denna tid"
            iconType="arrow"
            iconPlacement="right"
            clicked={() => onTimeSelected(nextFree?.[0])}
          />
        </ListItemTitle>
      </ListItem>
    );
  };

  const checkDateIsBlocked = (date) => {
    const d = moment(date);

    for (let i = 0; i < actualAvailableTimes?.length; i++) {
      const current = actualAvailableTimes[i];
      const cD = moment(current.From);

      if (cD.isSame(d, "day")) {
        return false;
      } else {
        continue;
      }
    }

    return true;
  };

  React.useEffect(() => {
    if (nextFree?.length > 0) {
      setPickedDate(moment(nextFree?.[0]?.From).format("YYYY-MM-DD"));
    }
  }, [nextFree]);

  return (
    <>
      {!availableTimesLoading ? (
        <>
          {renderNextFree()}

          <ListItem>
            <ListItemTitle style={{ marginBottom: 12 }}>
              Välj datum för att se lediga tider
            </ListItemTitle>

            <NonConnectedDatePicker
              value={pickedDate}
              onChange={(date) => setPickedDate(date)}
              alwaysShow
              disableDayFn={checkDateIsBlocked}
            />
            {pickedDate && (
              <SplitListItemWrapper>{renderTimes()}</SplitListItemWrapper>
            )}
          </ListItem>
        </>
      ) : (
        "Laddar..."
      )}
    </>
  );
}
