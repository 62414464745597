import moment from "moment";
import * as React from "react";
import Chapter from "../components/Content/Chapter";
import Hero from "../components/Content/Hero";
import MyErrands from "../components/Content/MyErrands";
import News from "../components/Content/News";
import Notifications from "../components/Content/Notifications";
import PageTitle from "../components/Content/PageTitle";
import QuickLinks from "../components/Content/QuickLinks";
import ReportErrandItem from "../components/Content/ReportErrandItem";
import {
  ListItem,
  ListItemDate,
  ListItemTitle,
  ListItemWrapper,
} from "../components/Content/styles";
import { InnerBox } from "../components/sharedStyles";
import useCompanySetting from "../hooks/useCompanySetting";
import { buildQueryString } from "../store/base";
import { useFilteredCalendarEvents } from "../store/calendarEvents";
import { useReportErrand } from "../store/reportErrands";
import { ChapterWrapper } from "./styles";

export default function Home() {
  const [setting, settingLoading] = useCompanySetting();

  const hideErrands = setting?.hide_errand_handling;

  const eventQ = buildQueryString({
    bokamera_id__isnull: false,
  });
  const [events, eventsLoading] = useFilteredCalendarEvents(eventQ);

  const nextEvent = React.useMemo(() => {
    if (!events?.length) return null;

    const next = events.reduce((acc, cur) => {
      if (!acc) return cur;

      if (moment(acc.start).isAfter(moment(cur.start))) {
        return cur;
      }

      return acc;
    }, null);

    return next;
  }, [events]);

  const [nextEventReportErrand] = useReportErrand(nextEvent?.object_id);

  return (
    <>
      <Hero />

      <PageTitle
        subtitle={
          setting?.hello_description ||
          "Här hittar du information kring ditt boende"
        }
      >
        {setting?.hello_phrase || "Välkommen till Mina sidor"}
      </PageTitle>

      {nextEvent && (
        <div style={{ padding: "0 12px 12px 12px" }}>
          <ListItemWrapper>
            <ListItem>
              <ListItemTitle>
                Kommande händelse -{" "}
                {moment(nextEvent?.start).format("YYYY-MM-DD HH:mm")}
              </ListItemTitle>
              <ListItemDate>{nextEvent?.title}</ListItemDate>
              <ListItemDate>{nextEvent?.decription}</ListItemDate>
              <ListItemDate style={{ marginBottom: 12 }}>
                {nextEvent.dynamic_remind_content}
              </ListItemDate>

              <ListItemDate style={{ marginBottom: 12 }}>
                Gå in på ärendet om du vill boka om mötet
              </ListItemDate>

              {nextEventReportErrand && (
                <ReportErrandItem errand={nextEventReportErrand} />
              )}
            </ListItem>
          </ListItemWrapper>
        </div>
      )}

      <ChapterWrapper>
        <Chapter title="Snabbfunktioner">
          <QuickLinks hideErrands={hideErrands} />
        </Chapter>

        <Chapter title="Nyheter">
          <News />
        </Chapter>

        {!setting?.hide_errand_handling && (
          <Chapter title="Mina ärenden">
            <MyErrands />
          </Chapter>
        )}
        <Chapter title="Notiser">
          <Notifications />
        </Chapter>
      </ChapterWrapper>
    </>
  );
}
