import * as React from "react";
import * as SC from "./styles";

export default function TextButton({
  clicked,
  disabled,
  title,
  extraStyle,
  iconType,
  icon,
  iconPlacement = "left",
  inverted,
  underlined,
  red,
}) {
  const handleClick = (e) => {
    e.stopPropagation();
    clicked();
  };
  return (
    <SC.TextButton
      style={{ ...extraStyle }}
      red={red}
      {...{ inverted, underlined }}
      disabled={disabled}
      onClick={handleClick}
      iconPlacement={iconPlacement}
    >
      {(iconType || icon) && (
        <SC.TextButtonIcon
          {...{ inverted, red, iconType, icon, iconPlacement }}
        />
      )}
      {title}
    </SC.TextButton>
  );
}
