export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_EVENT_SENDEOUT",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_EVENT_SENDEOUT",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_EVENT_SENDEOUT",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_EVENT_SENDEOUT",
  DESTROY_FORM: "DESTROY_FORM_EVENT_SENDEOUT",
  SET_FORM_ERROR: "SET_FORM_ERROR_EVENT_SENDEOUT",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_EVENT_SENDEOUT",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_EVENT_SENDEOUT",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_EVENT_SENDEOUT",
  RESET_STATE: "RESET_STATE_EVENT_SENDEOUT",
  CLEAR_FETCHED: "CLEAR_FETCHED_EVENT_SENDEOUT",
  REMOVE_OBJECT: "REMOVE_OBJECT_EVENT_SENDEOUT",

  LIST_URL: "/events/sendout/list/",
  POST_URL: "/events/sendout/",
  PATCH_URL: "/events/sendout/",
  GET_URL: "/events/sendout/",

  STORE_NAME: "sendout",
};
