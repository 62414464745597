import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../store/base";

import * as SC from "./styles";

export default React.memo(
  ({
    storeName,
    fieldKey,
    method,
    instructionsKey,
    noMargin,
    extraStyles,
    noHelp,
    small,
    alwaysShowHelp,
    title,
    description,
    manualInstructions,
  }) => {
    const dispatch = useDispatch();

    const instructions =
      useFormField({
        storeName,
        method,
        fieldKey: instructionsKey || fieldKey,
      }) ?? manualInstructions;
    const value = useFormInstanceField({ storeName, fieldKey });
    const error = useFormError({ storeName, fieldKey });

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    const onChange = (data) => {
      dispatch(
        updateActiveFormInstance({ storeName, data: { [fieldKey]: data } })
      );
    };

    return (
      <SC.InputSpacing {...{ small, extraStyles, noMargin }}>
        <SC.CheckBoxWrapper {...{ noMargin, small }} key={fieldKey}>
          <SC.CheckBox
            {...{ small }}
            id={fieldKey}
            checked={Boolean(value)}
            onChange={({ target: { checked } }) => onChange(checked)}
          />
          <label htmlFor={fieldKey}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {title}
              {instructions._required ? "*" : ""}
            </div>
          </label>
        </SC.CheckBoxWrapper>
        {alwaysShowHelp && <div>{description}</div>}
        {!!error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
      </SC.InputSpacing>
    );
  }
);
