import { ChapterWrapper } from "../../../views/styles";
import YesNoButton from "../../Buttons/YesNoButtons";
import {
  InnerBox,
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../sharedStyles";
import Chapter from "../Chapter";
import MediaDisplay from "../MediaDisplay";

export default function ChecklistRows({
  checklistIndex,
  checklistRows,
  onAcceptRow,
  onDeclineRow,
}) {
  const current = checklistRows?.[checklistIndex];
  const hasCurrent = !!current;

  return (
    <ChapterWrapper>
      <Chapter
        title={`Egenkontroll ${checklistIndex + 1} av ${checklistRows?.length}`}
        extraStyle={{ padding: 0 }}
      >
        {hasCurrent && (
          <InnerBox style={{ maxWidth: 450 }}>
            <OverviewTitleWrapper>
              <OverviewTitleWithSubtitleWrapper>
                <OverviewTitle small>{current?.title}</OverviewTitle>
                <OverviewSubtitle>{current?.description}</OverviewSubtitle>
              </OverviewTitleWithSubtitleWrapper>
            </OverviewTitleWrapper>

            {current?.files?.length > 0 && (
              <InnerBox>
                <div style={{ fontWeight: 500, fontSize: 14, marginBottom: 0 }}>
                  Instruktionsfiler
                </div>
                <div
                  style={{ fontWeight: 400, fontSize: 12, marginBottom: 12 }}
                >
                  Klicka på en fil för att öppna den
                </div>
                <MediaDisplay noName files={current?.files} />
              </InnerBox>
            )}

            <YesNoButton
              onYes={() => onAcceptRow(current.id)}
              onNo={() => onDeclineRow(current.id)}
            />
          </InnerBox>
        )}
      </Chapter>
    </ChapterWrapper>
  );
}
