import styled, { css } from "styled-components";

export const OverviewTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const OverviewTitle = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.headerMedium};
  font-size: ${(p) => p.theme.fontSizes.headerMedium};
  color: ${(p) => p.theme.colors.primaryText};
  display: flex;
  align-items: center;
  line-height: 100%;

  ${(p) =>
    p.small &&
    css`
      font-weight: ${(p) => p.theme.fontWeights.headerSmall};
      font-size: ${(p) => p.theme.fontSizes.headerSmall};
    `}

  ${(p) =>
    p.medium &&
    css`
      font-weight: ${(p) => p.theme.fontWeights.headerMedium};
      font-size: ${(p) => p.theme.fontSizes.headerMedium};
    `}
  @media(${(p) => p.theme.mediaQueries.desktopSmall}) {
    ${(p) =>
      !p.noColumn &&
      css`
        flex-direction: column;
        align-items: flex-start;
      `}
  }
`;

export const OverviewTitleWithSubtitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OverviewSubtitle = styled.div`
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  color: ${(p) => p.theme.colors.secondaryText};
  margin-top: 4px;
`;

export const InnerBox = styled.div`
  background-color: white;
  width: 100%;
  padding: 12px;
  border: ${(p) =>
    p.error ? p.theme.borders.standardRed : p.theme.borders.standard};
  box-shadow: ${(p) =>
    p.error ? p.theme.boxShadows.standardRed : p.theme.boxShadows.default};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  font-family: "Inter", sans-serif;
  position: relative;

  ${(p) =>
    p.onClick &&
    css`
      &:hover {
        cursor: pointer;
        background-color: ${(p) => p.theme.colors.blueLight};
      }
    `}
`;

export const DetailInnerWrapper = styled.div`
  position: relative;
  flex: 1;
  padding: 12px 24px 12px 24px;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  min-height: ${(p) => p.height || "200px"};
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 12px;
  }
`;

export const DetailPageBox = styled.div`
  position: relative;
  background-color: white;
  padding: 16px;
  border: ${(p) =>
    p.error ? p.theme.borders.standardRed : p.theme.borders.standard};
  border-radius: 8px;
  box-shadow: ${(p) =>
    p.error ? p.theme.boxShadows.standardRed : p.theme.boxShadows.default};
  display: flex;
  flex-direction: column;
  margin: 12px 0;

  ${(p) =>
    p.flexStart &&
    css`
      align-self: flex-start;
    `}

  ${(p) =>
    p.centerAll &&
    css`
      align-items: center;
      justify-content: center;
    `}
`;
