import { clearFetched } from "./base/store/actions";
import { debounce } from "lodash";

import { constants as apartmentConstants } from "./apartments";
import { constants as commonAreaConstants } from "./commonAreas";
import { constants as industrialConstants } from "./industrialPremises";
import { constants as errandComponentConstants } from "./errandComponents";
import { constants as reportErrandSettingConstants } from "./reportErrandSettings";
import { constants as reportErrandConstants } from "./reportErrands";
import { constants as inspectionErrandConstants } from "./inspectionErrands";
import { constants as calendarEventConstants } from "./calendarEvents";
import { constants as calendarConstants } from "./calendar";
import { constants as sendoutConstants } from "./sendout";
import { constants as notesConstants } from "./notes";
import { constants as leaseConstants } from "./leaseContracts";
import { constants as otherConstants } from "./otherContracts";
import { constants as parkingContractConstants } from "./parkingContracts";
import { constants as parkingSpotConstants } from "./parkingSpots";
import { constants as tenantConstants } from "./tenants";
import { constants as userConstants } from "./users";
import { constants as notificationConstants } from "./notifications";
import { constants as brfpremisConstants } from "./brfPremises";

export function toMoneyString(value, withCurrency) {
  if (value == null) {
    return "-";
  }

  if (withCurrency) return Math.round(value).toLocaleString("sv") + " SEK";

  return Math.round(value).toLocaleString("sv");
}

export const fetchMethodFromKind = (kind, filter) => {
  switch (kind) {
    default:
      return undefined;
  }
};

export const redirectMethodFromKind = (kind) => {
  switch (kind) {
    default:
      return undefined;
  }
};

export const resetAllStates = () => {
  return async (dispatch) => {
    // dispatch({
    //   type: tagConstants.RESET_STATE,
    //   payload: {},
    // });
  };
};

const getConstants = (key) => {
  switch (key) {
    case "events.sendout":
      return sendoutConstants;
    case "standard.leasecontract":
      return leaseConstants;
    case "accounts.tenant":
      return tenantConstants;
    case "accounts.user":
      return userConstants;
    case "standard.industrialpremises":
      return industrialConstants;
    case "standard.apartment":
      return apartmentConstants;
    case "standard.commonarea":
      return commonAreaConstants;
    case "standard.othercontract":
      return otherConstants;
    case "standard.parkingcontract":
      return parkingContractConstants;
    case "standard.parkingspot":
      return parkingSpotConstants;
    case "errands.reporterrand":
      return reportErrandConstants;
    case "errands.inspectionerrand":
      return inspectionErrandConstants;
    case "errands.reporterrandsetting":
      return reportErrandSettingConstants;
    case "errands.component":
      return errandComponentConstants;
    case "events.todo":
      return calendarEventConstants;
    case "events.calendar":
      return calendarConstants;
    case "general.note":
      return notesConstants;
    case "events.notification":
      return notificationConstants;
    case "brf.brfpremis":
      return brfpremisConstants;
    // case "brf.brfcontract":
    //   return brfcontractConstants;

    default:
      return undefined;
  }
};

/**
 * object with debounce function dynamically created when needed
 * key            :  value
 * socketStoreName: debounced function
 */
const debouncedDefs = {};

const updateLocalStore = ({ socketStoreName, dispatch }) => {
  const constants = getConstants(socketStoreName);
  dispatch(clearFetched(constants, true));
};

export const handleSocketUpdates = (event, dispatch) => {
  const data = JSON.parse(event?.data);

  let handled = [];

  data.forEach((cacheStr) => {
    const clenadCacheStr = cacheStr.replace("list___.", "");
    const [appLabel, modelName] = clenadCacheStr.split("_");
    const socketStoreName = `${appLabel}.${modelName}`;
    if (handled.includes(socketStoreName)) {
      return;
    }

    const constants = getConstants(socketStoreName);

    // not found or we don't care about it;
    if (!constants) return;

    if (debouncedDefs[socketStoreName]) {
      const debouncedFunc = debouncedDefs[socketStoreName];

      debouncedFunc({ socketStoreName, dispatch });
    } else {
      debouncedDefs[socketStoreName] = debounce(updateLocalStore, 1000);
      const debouncedFunc = debouncedDefs[socketStoreName];

      debouncedFunc({ socketStoreName, dispatch });
    }

    handled.push(socketStoreName);
    return;
  });
};
