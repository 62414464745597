import React from "react";
import ReactDOM from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import "./index.css";
import App from "./App";
import { store } from "./store/store";

export const ENVIRONMENTS = {
  PROD: "portal.pigello",
  STAGING: "staging.portal.pigello",
  ALPHA: "demo.portal.pigello.se",
  DEV: "localhost",
};

export let currentEnv;
Object.keys(ENVIRONMENTS).forEach((key) => {
  const val = ENVIRONMENTS[key];
  if (window.location.host.includes(val)) {
    currentEnv = val;
  }
});

export const SOCKET_URL =
  currentEnv === ENVIRONMENTS.PROD
    ? "wss://9eabjypqx5.execute-api.eu-north-1.amazonaws.com/production"
    : currentEnv === ENVIRONMENTS.STAGING
    ? "wss://4abiipmiz2.execute-api.eu-north-1.amazonaws.com/staging"
    : "wss://nrvmskesh1.execute-api.eu-north-1.amazonaws.com/dev";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>
);
