const convertHexToRGBA = (hexCode, opacity) => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

const theme = (companyColor) => {
  const primary = `#${companyColor}`;
  const faded = convertHexToRGBA(primary, 10);

  return {
    colors: {
      primaryBlue: companyColor ? primary : "#5165FB",
      primaryFaded: companyColor ? faded : "rgba(81,101,251,.2)",
      primaryBlueFaded: "#7E8EFF",
      contrastBlue: "#03214b",
      primaryBlueDarkened: "#4253D2",

      backgroundGray: "#f9fbfd", // default BG color
      gray1: "#F3F4F6",
      gray2: "#979FAE",
      gray3: "#5C667B",
      gray4: "#444A55",
      graphite: "#202225",

      // status colors
      green: "#2ec177",
      greenLight: "#e4fbef",
      greenDark: "#24995e",
      yellow: "#ffBA33",
      yellowLight: "#fff4de",
      red: "#ea5050",
      redLight: "#fddddd",
      blue: "#499dff",
      blueLight: "#e4f1ff",
      purple: "#9258f0",
      purpleLight: "#f2ebff",

      primaryText: "#444A55",
      secondaryText: "#5C667B",
    },

    fontSizes: {
      headerLarge: "30px",
      headerMedium: "24px",
      headerSmall: "18px",
      headerXSmall: "14px",
      data: "18px",
      body: "14px",
      mini: "12px",
    },

    fontWeights: {
      headerLarge: 600,
      headerMedium: 600,
      headerSmall: 500,
      headerXSmall: 400,
      data: 500,
      body: 400,
    },

    kerning: {
      headerLarge: "-1px",
    },

    borders: {
      standard: "thin solid #f3f4f6",
      standardRed: "thin solid rgb(234, 80, 80,.4)",
      dark: "thin solid #979FAE",
    },

    boxShadows: {
      standard: "0px 2px 11px -1px rgba(0, 0, 0, 0.2)",
      standardUnder: "0px 3px 5px 0px rgba(0, 0, 0, 0.2)",
      discrete:
        "0 0.25em 0.5em -0.125em rgba(50, 50, 50, 0.075), 0 0px 2px 1px rgba(50, 50, 50, 0.015) !important",
      small: "0px 2px 7px -1px rgba(0, 0, 0, 0.2)",
      highlight: "0 0px 6px 1px rgba(0,0,0,.10)",

      standardGreen: "0px 2px 11px 5px rgba(46, 193, 119, 0.2)",
      standardRed: "0px 2px 11px 5px rgba(234, 80, 80, 0.2)",
      standardBlue: "0px 2px 11px 5px rgba(73, 157, 255, 0.2)",

      default: "0px 10px 40px rgba(36, 40, 48, 0.05)",
    },

    zIndex: {
      modal: "9999",
      modalBackdrop: "9998",
    },

    spacing: (multiplier) => `${multiplier * 4}px`,

    mediaQueries: {
      mobileSmall: "max-width: 340px",
      mobile: "max-width: 768px",
      desktop: "min-width: 769px",
      desktopSmall: "max-width: 1200px",
    },
  };
};

export default theme;
