import * as React from "react";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import Chapter from "../components/Content/Chapter";
import Hero from "../components/Content/Hero";
import InfoItem from "../components/Content/InfoItem";
import PageTitle from "../components/Content/PageTitle";
import { InnerBox } from "../components/sharedStyles";
import { ChapterWrapper } from "./styles";
import useTenantInvoiceData, {
  useTenantPreviewInvoiceData,
} from "../store/tenantInvoicing/hooks/retrieve";
import { StatusLabel } from "../components/Content/styles";
import { billectaStateMap } from "./Invoicing";
import TextButton from "../components/Buttons/TextButton";
import { toMoneyString } from "../store/utils";
import PrimaryButton from "../components/Buttons/PrimaryButton";
import { exportEditableDoc } from "../store/editabledocs";
import OverlaySpinner from "../components/Content/Loaders/OverlaySpinner";
import useScreenSize from "../hooks/useScreenSize";

export default function Invoice() {
  const { id } = useParams();

  const [invoiceData, setInvoiceData] = React.useState(null);
  const [tenantInvoiceData, tenantInvoiceDataLoading] = useTenantInvoiceData();
  const [previewData, previewDataLoading] = useTenantPreviewInvoiceData(id);
  const { push } = useHistory();
  const { isMobile } = useScreenSize();

  React.useEffect(() => {
    if (tenantInvoiceData !== undefined && !tenantInvoiceDataLoading) {
      setInvoiceData(tenantInvoiceData?.InvoiceActions || null);
    }
  }, [tenantInvoiceData, tenantInvoiceDataLoading]);

  const currentInvoice = invoiceData?.find((i) => i.ActionPublicId === id);

  const openInvoice = async () => {
    let data = await exportEditableDoc({
      editableDoc: { docData_: previewData?.data },
      forShow: true,
    });

    const blob = new Blob([data], { type: "application/pdf" });
    const link = window.URL.createObjectURL(blob);
    window.open(link, "", "height=650,width=840");
  };

  return (
    <>
      {!isMobile && <Hero />}
      <TextButton
        title="Tillbaka till alla avier"
        clicked={() => push("/invoices")}
        iconType="arrow-back"
        extraStyle={{ marginTop: 12 }}
      />

      <PageTitle>Faktura nr {currentInvoice?.InvoiceNumber}</PageTitle>

      <ChapterWrapper>
        <Chapter title="Fakturainformation">
          <InnerBox>
            <InfoItem
              title="Status"
              value={
                <StatusLabel state={billectaStateMap[currentInvoice?.Stage]}>
                  {billectaStateMap[currentInvoice?.Stage]}
                </StatusLabel>
              }
            />
            <InfoItem
              title="Fakturadatum"
              value={moment(currentInvoice?.InvoiceDate)?.format("YYYY-MM-DD")}
            />
            <InfoItem
              title="Förfallodatum"
              value={moment(currentInvoice?.DueDate)?.format("YYYY-MM-DD")}
            />
            {currentInvoice?.ClosedDate && (
              <InfoItem
                title="Betalades"
                value={moment(currentInvoice?.ClosedDate)?.format("YYYY-MM-DD")}
              />
            )}
            <InfoItem title="OCR" value={currentInvoice?.OCR} />
            <InfoItem
              title="Autogiro"
              value={
                currentInvoice?.AutogiroWithdrawalEnabled ? (
                  <StatusLabel state={0}>Aktivt</StatusLabel>
                ) : (
                  <StatusLabel state={3}>Inaktivt</StatusLabel>
                )
              }
            />
            <InfoItem
              title="Belopp"
              value={toMoneyString(
                currentInvoice?.InvoicedAmount?.ValueForView,
                true
              )}
            />
            <InfoItem
              title="Återstående belopp"
              value={toMoneyString(
                currentInvoice?.CurrentAmount?.ValueForView,
                true
              )}
            />
          </InnerBox>
        </Chapter>

        <Chapter title="Visa faktura">
          <InnerBox>
            {previewDataLoading && <OverlaySpinner />}
            <PrimaryButton
              disabled={!previewData}
              title="Hämta faktura i PDF-format"
              clicked={openInvoice}
            />
          </InnerBox>
        </Chapter>
      </ChapterWrapper>
    </>
  );
}
