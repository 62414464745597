import { cloneDeep } from "lodash";
import {
  get,
  options,
  destroyForm,
  post,
  patch,
  getPagination,
  getAllFetchProcessName,
  getFiltered,
  clearFetched,
} from "../../base";
import { getSingleFetchProcessName } from "../../base/utils";

import constants from "./constants";

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (
  querystring,
  callback,
  taskToken,
  initiatedBySocket
) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({
    url,
    constants,
    querystring,
    callback,
    taskToken,
    initiatedBySocket,
  });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

const complementedPreProcess = (data, preProcess) => {
  let cloned;
  if (preProcess) {
    cloned = preProcess(data);
  } else {
    cloned = cloneDeep(data);
  }

  return cloned;
};

export const create = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
}) => {
  return post({
    url: constants.POST_URL,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
  });
};

export const update = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
}) => {
  const url = `${constants.PATCH_URL}${id}`;
  const fullPreProcess = (data) => complementedPreProcess(data, preProcess);
  return patch({
    url,
    constants,
    processSuccess,
    processError,
    errorCallback,
    successCallback,
    preProcess: fullPreProcess,
  });
};
export const clearFetchedData = () => {
  return clearFetched(constants);
};
