import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { performLogutRedirect } from "../store/axios";
import { getSingleFetchProcessName, getAllFetchProcessName } from "../utils";

/**
 * Returns the object if it exists in store
 *
 * If object doesn't exist in store, it will be fetched from backend
 * and then returned.
 *
 * undefined will be returned if we're wating for an object
 *
 * this method is not intended to be used directly,
 * but should instead be used as a tool by specific object types
 *
 * @param {string} storeName name of store
 * @param {Number} id value of backend provided id to fetch
 */
export function useObject({
  storeName,
  id,
  skipLogout,
  fetchMethod,
  processNames,
  usingAll,
  loggedOutCall,
}) {
  const [hasAttempted, setHasAttempted] = React.useState(false);
  const dispatch = useDispatch();
  const possibleProcessNames = processNames || [
    getAllFetchProcessName(),
    getSingleFetchProcessName(id),
  ];

  const userLoggedin = useSelector((state) => !!state.app.authorization);
  const hasAttemptedLogin = useSelector(
    (state) => !!state.app.hasAttemptedLogin
  );

  const existing = useSelector((state) => state[storeName].all[id]);
  const tempCopyExisting = useSelector((state) => state[storeName].allCopy[id]);
  const isFetching = useSelector(
    (state) =>
      (usingAll && state[storeName].hasFetchedAll) ||
      state[storeName].inProgress.some((p) => possibleProcessNames.includes(p))
  );

  if (!userLoggedin && !loggedOutCall) {
    if (hasAttemptedLogin && !skipLogout) {
      performLogutRedirect(`useobject ${storeName}`);
    }
    return [undefined, isFetching];
  }
  // first check if it exists in store
  if (existing) {
    return [existing, isFetching];
  }

  // check if we have a local copy while fetching an updated object, i.e. after a socket update has cleared the state
  // this prevents the flickering caused by clearing the state
  if (tempCopyExisting) {
    if (!isFetching && id) {
      dispatch(fetchMethod(id));
    }
    return [tempCopyExisting, false];
  }

  // if it doesn't exist check if it currently fetching
  // if so, we wait for result

  if (isFetching) {
    return [undefined, isFetching];
  }

  // if id wasn't set, return undefined as well
  if (!id) {
    return [undefined, isFetching];
  }

  // we're not waiting, so we should initiate a retrival
  if (!hasAttempted) {
    setHasAttempted(true);
    dispatch(fetchMethod(id));
  }

  return [undefined, isFetching];
}

export function useObjects({ storeName, filterMethod, fetchMethod }) {
  const dispatch = useDispatch();

  const userLoggedin = useSelector((state) => !!state.app.authorization);

  const hasAttemptedLogin = useSelector(
    (state) => !!state.app.hasAttemptedLogin
  );

  const existing = useSelector((state) => Object.values(state[storeName].all));
  const tempCopyExisting = useSelector((state) =>
    Object.values(state[storeName].allCopy)
  );
  const isFetching = useSelector(
    (state) =>
      state[storeName].hasFetchedAll ||
      state[storeName].inProgress.includes(getAllFetchProcessName())
  );
  if (!userLoggedin) {
    if (hasAttemptedLogin) {
      performLogutRedirect(`useobjects ${storeName}`);
    }
    return [undefined, isFetching];
  }

  // first check if it exists in store
  if (existing.length) {
    const distinct = existing.filter((e, index) => {
      if (!e.id) {
        return true;
      }
      return existing.findIndex((en) => en.id === e.id) === index;
    });

    // it exists, apply filter if we need to, otherwice just return
    if (!filterMethod) {
      return [distinct, isFetching];
    }
    return [distinct.filter(filterMethod), isFetching];
  }

  // then check if we have a local copy while fetching new
  if (tempCopyExisting.length) {
    if (!isFetching) {
      dispatch(fetchMethod());
    }

    const distinct = tempCopyExisting.filter((e, index) => {
      if (!e.id) {
        return true;
      }
      return tempCopyExisting.findIndex((en) => en.id === e.id) === index;
    });

    // it exists, apply filter if we need to, otherwice just return
    if (!filterMethod) {
      return [distinct, false];
    }
    return [distinct.filter(filterMethod), false];
  }

  // if it doesn't exist check if it is currently beeing fetched
  // if so, we wait for that

  if (isFetching) {
    return [[], isFetching];
  }

  // we're not waiting, so we should initiate a retrival
  dispatch(fetchMethod());

  return [[], isFetching];
}

export function useFilteredObjects({
  storeName,
  fetchMethod,
  querystring,
  skipLogout,
}) {
  const dispatch = useDispatch();
  // undefined, null e.g -> "";
  const formattedQueryString = querystring || "";

  const userLoggedin = useSelector((state) => !!state.app.authorization);

  const hasAttemptedLogin = useSelector(
    (state) => !!state.app.hasAttemptedLogin
  );

  const existing = useSelector(
    (state) => state[storeName].filtered[formattedQueryString]
  );
  const tempCopyExisting = useSelector(
    (state) => state[storeName].filteredCopy[formattedQueryString]
  );
  const isFetching = useSelector((state) =>
    state[storeName].inProgress.includes(formattedQueryString)
  );

  const filteredObjects = useSelector((state) => {
    if (!existing) {
      return undefined;
    }

    const distinct = existing.filter((id, index) => {
      return existing.indexOf(id) === index;
    });

    let result = [];
    distinct.forEach((id) => {
      const obj = state[storeName].all[id];
      if (obj) {
        result.push(obj);
      }
    });

    return result;
  });

  const tempCopyfilteredObjects = useSelector((state) => {
    if (!tempCopyExisting) {
      return undefined;
    }

    const distinct = tempCopyExisting.filter((id, index) => {
      return tempCopyExisting.indexOf(id) === index;
    });

    let result = [];
    distinct.forEach((id) => {
      const obj = state[storeName].allCopy[id];
      if (obj) {
        result.push(obj);
      }
    });

    return result;
  });

  if (!userLoggedin) {
    if (hasAttemptedLogin && !skipLogout) {
      performLogutRedirect(`usefilteredobjects ${storeName}`);
    }
    return [undefined, isFetching];
  }

  // first check if it exists in store
  if (filteredObjects) {
    // it exists, apply filter if we need to, otherwice just return
    return [filteredObjects, isFetching];
  }

  if (tempCopyfilteredObjects) {
    if (!isFetching && querystring != null) {
      dispatch(fetchMethod(formattedQueryString));
    }

    return [tempCopyfilteredObjects, false];
  }

  if (isFetching || querystring === null) {
    // if it doesn't exist check if it is currently beeing fetched
    // if so, we wait for that

    return [[], isFetching];
  }

  // we're not waiting, so we should initiate a retrival
  dispatch(fetchMethod(formattedQueryString));

  return [[], isFetching];
}

export function usePagination({ storeName, fetchMethod, querystring }) {
  const dispatch = useDispatch();

  const formattedQueryString = querystring || "";

  const userLoggedin = useSelector((state) => !!state.app.authorization);

  const hasAttemptedLogin = useSelector(
    (state) => !!state.app.hasAttemptedLogin
  );

  const existing = useSelector(
    (state) => state[storeName].paginations[formattedQueryString]
  );
  const tempCopyExisting = useSelector(
    (state) => state[storeName].paginationsCopy[formattedQueryString]
  );

  const count = existing?.count || tempCopyExisting?.count || 0;
  const isFetching = useSelector((state) =>
    state[storeName].inProgress.includes(formattedQueryString)
  );

  const paginatedObjects = useSelector((state) => {
    if (!existing) {
      return undefined;
    }
    const distinct = existing.results.filter((id, index) => {
      return existing.results.indexOf(id) === index;
    });

    let result = [];
    distinct.forEach((id) => {
      const obj = state[storeName].all[id];
      if (obj) {
        result.push(obj);
      }
    });

    return result;
  });

  const tempCopyPaginatedObjects = useSelector((state) => {
    if (!tempCopyExisting) {
      return undefined;
    }
    const distinct = tempCopyExisting.results.filter((id, index) => {
      return tempCopyExisting.results.indexOf(id) === index;
    });

    let result = [];
    distinct.forEach((id) => {
      const obj = state[storeName].allCopy[id];
      if (obj) {
        result.push(obj);
      }
    });

    return result;
  });

  if (!userLoggedin) {
    if (hasAttemptedLogin) {
      performLogutRedirect(`usepaginatedobjects ${storeName}`);
    }
    return [undefined, isFetching, count];
  }

  // first check if we have pagination here
  if (paginatedObjects) {
    return [
      {
        ...existing,
        results: paginatedObjects,
      },
      isFetching,
      count,
    ];
  }

  if (tempCopyPaginatedObjects) {
    if (!isFetching && querystring != null) {
      dispatch(fetchMethod(formattedQueryString));
    }

    return [
      {
        ...tempCopyExisting,
        results: tempCopyPaginatedObjects,
      },
      false,
      count,
    ];
  }

  if (isFetching || querystring === null) {
    // if we're fetching, wait for that, querystring beeing null -> don't make request at all
    return [{}, isFetching, count];
  }

  dispatch(fetchMethod(formattedQueryString));

  return [{}, isFetching, count];
}

export function usePaginationCount({ storeName, fetchMethod, querystring }) {
  const dispatch = useDispatch();

  const formattedQueryString = querystring || "";

  const userLoggedin = useSelector((state) => !!state.app.authorization);

  const hasAttemptedLogin = useSelector(
    (state) => !!state.app.hasAttemptedLogin
  );

  const existing = useSelector(
    (state) => state[storeName].paginations[formattedQueryString]
  );
  const tempCopyExisting = useSelector(
    (state) => state[storeName].paginationsCopy[formattedQueryString]
  );
  const isFetching = useSelector((state) =>
    state[storeName].inProgress.includes(formattedQueryString)
  );

  const paginatedCount = useSelector((state) => {
    if (!existing) {
      return undefined;
    }
    const count = existing.count;

    return count;
  });

  const tempCopyPaginatedCount = useSelector((state) => {
    if (!tempCopyExisting) {
      return undefined;
    }
    const count = tempCopyExisting.count;

    return count;
  });

  if (!userLoggedin) {
    if (hasAttemptedLogin) {
      performLogutRedirect(`usepaginatedcount ${storeName}`);
    }
    return [undefined, isFetching];
  }

  // first check if we have pagination here
  if (paginatedCount != null) {
    return [paginatedCount, isFetching];
  }

  if (tempCopyPaginatedCount != null) {
    if (!isFetching && querystring != null) {
      dispatch(fetchMethod(formattedQueryString));
    }

    return [tempCopyPaginatedCount, false];
  }

  if (isFetching || querystring === null) {
    // if we're fetching, wait for that, querystring beeing null -> don't make request at all
    return [undefined, isFetching];
  }

  dispatch(fetchMethod(formattedQueryString));

  return [undefined, isFetching];
}

export function useInProgress({ storeName, name }) {
  const formattedName = name || "";
  const inProgress = useSelector((state) => {
    return state[storeName].inProgress.includes(formattedName);
  });
  return inProgress;
}
