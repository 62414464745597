import * as SC from "./styles";

export default function ObjectImages({ images = [] }) {
  const openImage = (src) => {
    window.open(src, "_blank").focus();
  };

  return (
    <SC.ImageContainer>
      {images?.map((i) => (
        <SC.ImageWrapper key={i.id}>
          <SC.Image onClick={() => openImage(i.image.get)} src={i.image.get} />
        </SC.ImageWrapper>
      ))}
    </SC.ImageContainer>
  );
}
