import { uploadMultiBase64ToS3 } from "../../s3Uploading";
import { addToast, TOAST_TYPES } from "../../toasts";

export const createUploadHandler = async ({
  formInstance,
  responseData,
  dispatch,
}) => {
  try {
    let urls = [];
    let files = [];

    // errand report_files
    const errandReportImages = formInstance?.report_files;
    const errandReportPutUrls = responseData?.report_files?.map(
      (f) => f?.file?.put
    );

    if (errandReportImages?.length) {
      errandReportPutUrls.forEach((url, index) => {
        const image = errandReportImages?.[index];

        if (image?._tempData && url) {
          urls.push(url);
          files.push(image?._tempData?.data);
        }
      });
    }

    if (!urls.length) {
      return;
    }

    await uploadMultiBase64ToS3({
      signedUrls: urls,
      files: files,
    });
    return;
  } catch (e) {
    dispatch(
      addToast({
        type: TOAST_TYPES.ERROR,
        title: "Filuppladdning misslyckades",
      })
    );
    return;
  }
};
