import baseReducer from "../../base/store/reducer";
import constants from "./constants";

export default (state, action) => {
  const newState = baseReducer(state, action, constants);

  const { type, payload } = action;

  switch (type) {
    case constants.INSERT_INTO_NOTIF_OBJECTS:
      return {
        ...newState,
        [`${payload?.content_type}_${payload.object_id}`]: payload.has_block,
      };
    default:
      return newState;
  }
};
