import {
  options,
  destroyForm,
  patch,
  getPagination,
  getFiltered,
  get,
} from "../../base";
import constants from "./constants";
import {
  markNotificationsAsRead,
  getNotificationObject,
  toggleNotificationObject,
} from "./services";

export const performFilter = (querystring, callback, taskToken) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({ url, constants, querystring, callback, taskToken });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

const preProcessSingleSetting = (data) => {
  data.id = constants.SETTING_STATE_ID;
  return data;
};

export const getSingle = () => {
  return get({
    url: constants.GET_CONFIG_URL,
    constants,
    name: constants.SETTING_STATE_ID,
    preProcess: preProcessSingleSetting,
  });
};

export const getPatchForm = () => {
  return options({
    url: constants.PATCH_CONFIG_URL,
    constants,
    method: "PATCH",
  });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

export const markAsRead = (readlist, success) => {
  return async (dispatch) => {
    await markNotificationsAsRead(readlist);

    dispatch(destroyForm({ constants, method: "PATCH", success }));
  };
};

export const getNotifObject = ({ content_type, object_id }) => {
  return async (dispatch) => {
    try {
      const { has_block } = await getNotificationObject({
        content_type,
        object_id,
      });

      dispatch({
        type: constants.INSERT_INTO_NOTIF_OBJECTS,
        payload: { content_type, object_id, has_block },
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const toggleNotifObject = ({
  content_type,
  object_id,
  successCallback,
  errorCallback,
}) => {
  return async (dispatch) => {
    try {
      const { toggled_on } = await toggleNotificationObject({
        content_type,
        object_id,
      });

      dispatch({
        type: constants.INSERT_INTO_NOTIF_OBJECTS,
        payload: { content_type, object_id, has_block: toggled_on },
      });

      if (successCallback) {
        successCallback(toggled_on);
      }
    } catch (error) {
      errorCallback();
    }
  };
};

export const update = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  forceData,
}) => {
  return patch({
    url: constants.PATCH_CONFIG_URL,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
    forceData,
  });
};
