import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import TextButton from "../components/Buttons/TextButton";
import Hero from "../components/Content/Hero";
import OverlaySpinner from "../components/Content/Loaders/OverlaySpinner";
import PageTitle from "../components/Content/PageTitle";
import { InnerBox } from "../components/sharedStyles";
import useScreenSize from "../hooks/useScreenSize";
import { useSendout } from "../store/sendout";

export default function NewsArticle() {
  const { id } = useParams();

  const [newsArticle, newsArticleLoading] = useSendout(id);
  const { isMobile } = useScreenSize();

  const { push } = useHistory();

  return (
    <>
      {newsArticleLoading && <OverlaySpinner />}
      {!isMobile && <Hero />}
      <TextButton
        extraStyle={{ marginTop: 12 }}
        title="Tillbaka till alla nyheter"
        iconType="arrow-back"
        clicked={() => push("/news")}
      />
      <PageTitle
        subtitle={moment(newsArticle?.scheduled_for).format("YYYY-MM-DD HH:mm")}
      >
        {newsArticle?.title}
      </PageTitle>
      <div style={{ padding: "0 12px 12px 12px" }}>
        <InnerBox>
          <p
            dangerouslySetInnerHTML={{
              __html: newsArticle?.message?.replaceAll("\n", "<br>"),
            }}
          />
        </InnerBox>
      </div>
    </>
  );
}
