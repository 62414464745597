import * as SC from "./styles";

export default function ErrandImages({ images = [] }) {
  const openImage = (src) => {
    window.open(src, "_blank").focus();
  };

  return (
    <SC.ImageContainer>
      {images?.map((i) => (
        <SC.ImageWrapper key={i.id}>
          <SC.Image onClick={() => openImage(i.file.get)} src={i.file.get} />
          <SC.ImageDescription>
            {i.description || "Bilden saknar beskrivning"}
          </SC.ImageDescription>
        </SC.ImageWrapper>
      ))}
    </SC.ImageContainer>
  );
}
