import * as React from "react";
import { useDispatch } from "react-redux";
import {
  updateActiveFormInstance,
  useFormInstanceField,
} from "../../store/base";
import {
  useInvoicingForm,
  constants,
  update,
} from "../../store/tenantInvoicing";

import PrimaryButton from "../Buttons/PrimaryButton";
import TextButton from "../Buttons/TextButton";
import OverlaySpinner from "../Content/Loaders/OverlaySpinner";
import Select from "./Select";
import TextInput from "./TextInput";

export default function InvoiceSettingsForm({
  onCheckout,
  debtorInstance,
  isCompany,
}) {
  const dispatch = useDispatch();
  const storeName = constants.STORE_NAME;
  const method = "PATCH";
  const [loading, setLoading] = React.useState(false);
  const [hasSetInitial, setHasSetInitial] = React.useState(false);

  useInvoicingForm("PATCH", debtorInstance?.id);

  React.useEffect(() => {
    if (debtorInstance && !hasSetInitial) {
      setHasSetInitial(true);
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: debtorInstance,
        })
      );
    }
  }, [debtorInstance, dispatch, storeName, hasSetInitial]);

  const instance = useFormInstanceField({
    storeName,
    fieldKey: "",
  });

  const saveUpdate = () => {
    setLoading(true);

    dispatch(
      update({
        id: debtorInstance?.id,
        forceData: {
          email_invoice: instance?.email_invoice,
          gln: instance?.gln,
          intermediator: instance?.intermediator,
          e_invoice_bank: instance?.e_invoice_bank,
        },
        successCallback: () => {
          setLoading(false);

          onCheckout();
        },
        errorCallback: () => {
          setLoading(false);
        },
      })
    );
  };

  return (
    <>
      {loading && <OverlaySpinner />}

      <TextButton
        title="Avbryt och gå tillbaka"
        iconType="arrow-back"
        clicked={() => onCheckout()}
        extraStyle={{ marginBottom: 12 }}
      />

      <TextInput
        extraStyles={{ minWidth: "120px" }}
        storeName={storeName}
        fieldKey={"email_invoice"}
        method={method}
        title="E-post för fakturor"
      />

      {isCompany ? (
        <>
          <TextInput
            extraStyles={{ minWidth: "120px" }}
            storeName={storeName}
            fieldKey={"gln"}
            method={method}
            title="GLN/PEPPOL"
          />
          <TextInput
            extraStyles={{ minWidth: "120px" }}
            storeName={storeName}
            fieldKey={"intermediator"}
            method={method}
            title="Intermediatör"
          />
        </>
      ) : (
        <Select
          extraStyles={{ minWidth: "120px" }}
          storeName={storeName}
          fieldKey={"e_invoice_bank"}
          method={method}
          title="Bank för E-faktura"
        />
      )}

      <PrimaryButton
        extraStyle={{ marginTop: 12 }}
        title="Uppdatera inställning"
        clicked={saveUpdate}
      />
    </>
  );
}
