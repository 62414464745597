import moment from "moment";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { markAsRead } from "../../store/notifications/store/actions";
import * as SC from "./styles";

export const REDIRECT_FUNCTIONS = {
  "errands.reporterrand": (id) => `/errands/${id}`,
  "accounting.debtorinvoiceconfig": () => `/profile`,
};

export default function Notification({ notification }) {
  const { push } = useHistory();
  const dispatch = useDispatch();
  return (
    <SC.NewsItem
      key={notification.id}
      onClick={() => {
        dispatch(markAsRead([notification.id]));

        const urlMethod = REDIRECT_FUNCTIONS[notification.content_type];

        if (urlMethod) {
          push(urlMethod(notification.object_id));
        }
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "space-between",
        }}
      >
        <SC.NewsDate>
          {moment(notification.created_at).format("YYYY-MM-DD")}
        </SC.NewsDate>

        {!notification.read_by_me && <SC.NotReadDot />}
      </div>
      <SC.NewsTitle>{notification.title}</SC.NewsTitle>
    </SC.NewsItem>
  );
}
