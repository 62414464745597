import * as React from "react";
import { Route } from "react-router-dom";
import DesktopMenu from "../components/Navbar/DesktopMenu";
import Navbar from "../components/Navbar/Navbar";
import useScreenSize from "../hooks/useScreenSize";

export default function RouteBase({
  component: RouteComponent,
  path,
  exact,
  isReportView,
  isPowerPointReport,
}) {
  const { isMobile } = useScreenSize();
  return (
    <Route
      exact={exact}
      path={path}
      render={(matchProps) => (
        <div style={{ display: "flex" }}>
          {!isMobile && <DesktopMenu />}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: 60,
              flex: 1,
            }}
          >
            <Navbar />
            <RouteComponent {...matchProps} />
          </div>
        </div>
      )}
    />
  );
}
