import moment from "moment";
import { useHistory } from "react-router-dom";
import { useApartment } from "../../store/apartments";
import { useBrfPremises } from "../../store/brfPremises";
import { useIndustrialPremises } from "../../store/industrialPremises";
import { getErrandStatus } from "../../store/reportErrands/utils";
import { ListItem, ListItemDate, ListItemTitle, StatusLabel } from "./styles";

export default function InspectionErrandItem({ errand }) {
  const { colorStatus, display } = getErrandStatus(errand);

  const [apartment] = useApartment(errand.apartment?.id);
  const [indp] = useIndustrialPremises(errand.industrial_premises?.id);
  const [brfPremises] = useBrfPremises(errand.brf_premis?.id);

  const premises = apartment || indp || brfPremises;
  const { push } = useHistory();

  return (
    <ListItem onClick={() => push(`/inspections/${errand.id}`)}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 12,
        }}
      >
        <StatusLabel state={colorStatus}>{display}</StatusLabel>

        <ListItemDate>
          {moment(errand.created_at).format("YYYY-MM-DD")}
        </ListItemDate>
      </div>

      <ListItemTitle>
        {errand.errand_id} - {errand.str_representation}
      </ListItemTitle>

      <ListItemDate>
        {premises?.kind === "apartment"
          ? "Lgh"
          : premises?.kind === "industrialpremises"
          ? "Lokal"
          : "Bostadsrätt"}{" "}
        <span>{premises?.str_representation}</span>
      </ListItemDate>
    </ListItem>
  );
}
