import { useHistory, useParams } from "react-router-dom";
import TextButton from "../components/Buttons/TextButton";
import Chapter from "../components/Content/Chapter";
import Hero from "../components/Content/Hero";
import InfoItem from "../components/Content/InfoItem";
import ObjectImages from "../components/Content/ObjectImages";
import PageTitle from "../components/Content/PageTitle";
import { ListItemTitle } from "../components/Content/styles";
import { InnerBox } from "../components/sharedStyles";
import useScreenSize from "../hooks/useScreenSize";
import { useApartment } from "../store/apartments";
import { useBrfPremises } from "../store/brfPremises";
import { useIndustrialPremises } from "../store/industrialPremises";
import { useParkingSpot } from "../store/parkingSpots";
import { toMoneyString } from "../store/utils";
import { ChapterWrapper } from "./styles";

const getHookFromType = (type) => {
  if (type === "apartment") return useApartment;
  if (type === "industrial-premises") return useIndustrialPremises;
  if (type === "parking") return useParkingSpot;
  if (type === "brf") return useBrfPremises;
};

const getStrFromType = (type) => {
  if (type === "apartment") return "Lägenhet";
  if (type === "industrial-premises") return "Lokal";
  if (type === "parking") return "Parkeringsplats";
  if (type === "brf") return "Bostadsrätt";
};

export default function Object() {
  const { id, type } = useParams();
  const { push } = useHistory();
  const { isMobile } = useScreenSize();

  const useObjectHook = getHookFromType(type);
  const str = getStrFromType(type);

  const [object, objectLoading] = useObjectHook(id);

  return (
    <>
      {!isMobile && <Hero />}

      <TextButton
        extraStyle={{ marginTop: 12 }}
        title="Tillbaka till alla objekt"
        iconType="arrow-back"
        clicked={() => push("/objects")}
      />

      <PageTitle>
        {str} {object?.str_representation}
      </PageTitle>

      <ChapterWrapper>
        <Chapter title="Information">
          <InnerBox>
            {object?.brf_company && (
              <InfoItem
                title="Bostadsrättsförening"
                value={object?.brf_company?.str_representation}
              />
            )}
            <InfoItem
              title="Adress"
              value={object?.address?.base?.split(",")?.[0] || "-"}
            />
            <InfoItem
              title="Postnr"
              value={object?.address?.postal_code || "-"}
            />
            <InfoItem
              title="Kommun"
              value={object?.address?.municipality || "-"}
            />
            <InfoItem title="Stad" value={object?.address?.city || "-"} />
            {type !== "parking" && (
              <InfoItem title="Area" value={`${object?.area || 0}m2`} />
            )}
            {type !== "parking" && (
              <InfoItem
                title="Våning"
                value={object?.floors?.join(", ") || "-"}
              />
            )}
            {object?.quota != null && (
              <InfoItem title="Andel av förening" value={`${object.quota}%`} />
            )}
            {object?.fee_quota != null && (
              <InfoItem title="Andel av avgift" value={`${object.quota}%`} />
            )}
            {object?.access_date != null && (
              <InfoItem title="Tillträdesdatum" value={object.access_date} />
            )}
            {object?.realestate && (
              <InfoItem
                title="Fastighet"
                value={object?.realestate?.str_representation}
              />
            )}
          </InnerBox>
        </Chapter>

        <Chapter title="Bilder">
          <InnerBox>
            {object?.images?.length ? (
              <ObjectImages images={object?.images} />
            ) : (
              <ListItemTitle>Bilder saknas</ListItemTitle>
            )}
          </InnerBox>
        </Chapter>

        {type === "brf" && (
          <Chapter title="Ekonomi">
            <InnerBox>
              <InfoItem
                title="Månadsavgift"
                value={toMoneyString(object?.next_period_value, true)}
              />
              <InfoItem
                title={<>Månadsavgift per &#13217;</>}
                value={toMoneyString(
                  object?.next_period_value / (object?.area || 1),
                  true
                )}
              />
              <InfoItem
                title="Belopp inre fond"
                value={toMoneyString(object?.fund_value, true)}
              />
              <InfoItem
                title="Avsättning/månad"
                value={toMoneyString(
                  object?.next_period_value * (object?.fund_quota / 100 || 0),
                  true
                )}
              />
            </InnerBox>
          </Chapter>
        )}

        {/* <Chapter title="Planritning">
          <InnerBox></InnerBox>
        </Chapter> */}
      </ChapterWrapper>
    </>
  );
}
