import { cloneDeep } from "lodash";

import {
  getAllFetchProcessName,
  getFiltered,
  getSingleFetchProcessName,
  destroyForm,
  getPagination,
  options,
  patch,
  post,
  get,
} from "../../base";

import constants from "./constants";

export const SCRIVE_ACTIONS = {
  CANCEL: "cancel",
  RESTART: "restart",
  PROLONG: "prolong",
  REMIND: "remind",
  SET_AUTO_REMINDER: "setautoreminder",
};

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (querystring, callback, taskToken) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({ url, constants, querystring, callback, taskToken });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

const complementedPreProcess = (data, preProcess) => {
  let cloned;
  if (preProcess) {
    cloned = preProcess(data);
  } else {
    cloned = cloneDeep(data);
  }

  // fix scrive phone validation
  if (cloned.parties?.length) {
    cloned.parties.forEach((party) => {
      //remove when field added
      party.confirmation_delivery_method = party.delivery_method;

      if (party.fields?.length) {
        party.fields.forEach((field) => {
          if (field.kind === "phone") {
            if (field.value?.substr(0, 2) === "00") {
              // 0046723070119 -> +46723070119
              field.value = `+${field.value.substr(2, field.length)}`;
            } else if (field.value?.substr(0, 2) === "07") {
              // 0723070119 -> +46723070119
              field.value = `+46${field.value.substr(1, field.length)}`;
            } else if (field.value?.substr(0, 2) === "46") {
              // 0723070119 -> +46723070119
              field.value = `+${field.value}`;
            }
          }
        });
      }
    });
  }

  // the main file
  if (cloned?.docData_) {
    // file has been edited, editabledoc.doc has been set
    // so delete docData (on the clone, we have the reference on formInstance)
    delete cloned?.docData_;
  } else {
    // file has not been edited, don't send anything
    delete cloned?.doc;
  }

  // the attachments
  (cloned?.attachments || []).forEach((a) => {
    // will never be sent to backend
    delete a._tempData;
    delete a._referenceId;

    if (a.id) {
      // file has not been edited, so we can just use the id
      delete a.pdf;
    }
  });

  return cloned;
};

export const create = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
  forceData,
}) => {
  const fullPreProcess = (data) => complementedPreProcess(data, preProcess);
  return post({
    url: constants.POST_URL,
    constants,
    processSuccess,
    processError,
    errorCallback,
    successCallback,
    preProcess: fullPreProcess,
    forceData,
  });
};

export const update = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
  forceData,
}) => {
  const url = `${constants.PATCH_URL}${id}`;
  const fullPreProcess = (data) => complementedPreProcess(data, preProcess);
  return patch({
    url,
    constants,
    processSuccess,
    processError,
    errorCallback,
    successCallback,
    preProcess: fullPreProcess,
    forceData,
  });
};
