import moment from "moment";
import * as React from "react";
import { useHistory } from "react-router-dom";
import useCompanySetting from "../../hooks/useCompanySetting";
import useTenantInvoiceData from "../../store/tenantInvoicing/hooks/retrieve";
import theme from "../../theme";
import { SHOWN_STAGES_UNPAID } from "../../views/Invoicing";
import { InnerBox } from "../sharedStyles";
import * as SC from "./styles";
import { FiTool, FiCreditCard, FiArrowRight, FiKey } from "react-icons/fi";
import { getApptusUrls, getRCOUrls } from "../../store/app/services";
import { useDispatch } from "react-redux";
import { addToast } from "../../store/toasts";

export default function QuickLinks({ hideErrands }) {
  const dispatch = useDispatch();
  const [invoiceData, setInvoiceData] = React.useState(null);
  const [tenantInvoiceData, tenantInvoiceDataLoading] = useTenantInvoiceData();

  const [collectedApptusUrls, setCollectedApptusUrls] = React.useState(null);
  const [askApptusRedirect, setAskApptusRedirect] = React.useState(false);

  const [collectedRCOUrls, setCollectedRCOUrls] = React.useState(null);
  const [askRCORedirect, setAskRCORedirect] = React.useState(false);

  React.useEffect(() => {
    if (tenantInvoiceData !== undefined && !tenantInvoiceDataLoading) {
      setInvoiceData(tenantInvoiceData?.InvoiceActions || null);
    }
  }, [tenantInvoiceData, tenantInvoiceDataLoading]);

  const nextUnpaidInvoice = React.useMemo(() => {
    const unpaidInvoices = invoiceData?.filter((invoice) => {
      return SHOWN_STAGES_UNPAID.includes(invoice?.Stage);
    });

    const next = unpaidInvoices?.reduce((acc, cur) => {
      if (!acc) return cur;

      if (moment(acc.DueDate).isBefore(cur.DueDate)) return cur;

      return acc;
    }, null);

    return next;
  }, [invoiceData]);

  const [setting] = useCompanySetting();
  const { push } = useHistory();

  const performApptusUrlCollection = async () => {
    const data = await getApptusUrls(setting.id);

    const resultAmount = Object.keys(data).length;
    if (resultAmount === 0) {
      dispatch(
        addToast(
          "Kunde ej generera länk till Aptus",
          "Vänligen försök igen",
          "ERROR"
        )
      );
      setCollectedApptusUrls(null);
      setAskApptusRedirect(false);
      // close and temrinage
    } else if (resultAmount === 1) {
      window.open(Object.values(data)[0], "_blank");
      setCollectedApptusUrls(null);
      setAskApptusRedirect(false);
    } else {
      push("/aptuc-rco-links/aptus");
    }
  };

  const performRCOUrlCollection = async () => {
    const data = await getRCOUrls(setting.id);

    const resultAmount = Object.keys(data).length;
    if (resultAmount === 0) {
      dispatch(
        addToast(
          "Kunde ej generera länk till RCO",
          "Vänligen försök igen",
          "ERROR"
        )
      );
      setCollectedRCOUrls(null);
      setAskRCORedirect(false);
      // close and temrinage
    } else if (resultAmount === 1) {
      window.open(Object.values(data)[0], "_blank");
      setCollectedRCOUrls(null);
      setAskRCORedirect(false);
    } else {
      push("/aptuc-rco-links/rco");
    }
  };

  React.useEffect(() => {
    if (askApptusRedirect) {
      if (collectedApptusUrls == null) {
        performApptusUrlCollection();
      }
    } else {
      setCollectedApptusUrls(null);
    }
  }, [askApptusRedirect]);

  React.useEffect(() => {
    if (askRCORedirect) {
      if (collectedRCOUrls == null) {
        performRCOUrlCollection();
      }
    } else {
      setCollectedRCOUrls(null);
    }
  }, [askRCORedirect]);

  return (
    <SC.QuickLinkWrapper>
      {!hideErrands && (
        <SC.QuickLinkItem onClick={() => push("/create-errand")}>
          <SC.QuickLinkIconWrapper style={{ borderColor: theme().colors.blue }}>
            <FiTool color={theme().colors.blue} size={24} />
          </SC.QuickLinkIconWrapper>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <SC.QuickLinkTitle>Felanmälan</SC.QuickLinkTitle>
            <SC.QuickLinkSubTitle>Skapa ett nytt ärende</SC.QuickLinkSubTitle>
          </div>
          <FiArrowRight
            color={theme().colors.blue}
            size={24}
            style={{ marginLeft: "auto" }}
          />
        </SC.QuickLinkItem>
      )}
      {nextUnpaidInvoice && (
        <SC.QuickLinkItem
          onClick={() => push(`/invoices/${nextUnpaidInvoice.ActionPublicId}`)}
        >
          <SC.QuickLinkIconWrapper
            style={{ borderColor: theme().colors.green }}
          >
            <FiCreditCard color={theme().colors.green} size={24} />
          </SC.QuickLinkIconWrapper>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <SC.QuickLinkTitle>Nästa avi</SC.QuickLinkTitle>
            <SC.QuickLinkSubTitle>
              Förfaller:{" "}
              {moment(nextUnpaidInvoice?.DueDate).format("YYYY-MM-DD")}
            </SC.QuickLinkSubTitle>
          </div>
          <FiArrowRight
            color={theme().colors.green}
            size={24}
            style={{ marginLeft: "auto" }}
          />
        </SC.QuickLinkItem>
      )}
      {setting?.apptus_sso_customer_name_field != null &&
        setting?.apptus_sso_host != null && (
          <SC.QuickLinkItem onClick={() => setAskApptusRedirect(true)}>
            <SC.QuickLinkIconWrapper
              style={{ borderColor: theme().colors.purple }}
            >
              <FiKey color={theme().colors.purple} size={24} />
            </SC.QuickLinkIconWrapper>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <SC.QuickLinkTitle>Aptus inloggning</SC.QuickLinkTitle>
              <SC.QuickLinkSubTitle>
                Logga in på Aptus-portalen
              </SC.QuickLinkSubTitle>
            </div>
            <FiArrowRight
              color={theme().colors.purple}
              size={24}
              style={{ marginLeft: "auto" }}
            />
          </SC.QuickLinkItem>
        )}

      {setting?.rco_sso_customer_name_field != null &&
        setting?.rco_sso_host != null && (
          <SC.QuickLinkItem onClick={() => setAskRCORedirect(true)}>
            <SC.QuickLinkIconWrapper
              style={{ borderColor: theme().colors.purple }}
            >
              <FiKey color={theme().colors.purple} size={24} />
            </SC.QuickLinkIconWrapper>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <SC.QuickLinkTitle>RCO inloggning</SC.QuickLinkTitle>
              <SC.QuickLinkSubTitle>
                Logga in på RCO-portalen
              </SC.QuickLinkSubTitle>
            </div>
            <FiArrowRight
              color={theme().colors.purple}
              size={24}
              style={{ marginLeft: "auto" }}
            />
          </SC.QuickLinkItem>
        )}
      {/* <SC.QuickLinkItem
          style={{
            backgroundColor: theme(setting?.color_code).colors.purple,
            color: theme(setting?.color_code).colors.purpleLight,
          }}
        >
          <SC.QuickLinkTitle>FAQ</SC.QuickLinkTitle>
          <SC.QuickLinkSubTitle>Vanliga frågor och svar </SC.QuickLinkSubTitle>
        </SC.QuickLinkItem> */}
    </SC.QuickLinkWrapper>
  );
}
