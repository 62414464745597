import theme from "../../theme";
import { InfoItemWrapper } from "./styles";

export default function InfoItem({ title, value }) {
  const t = theme();

  return (
    <InfoItemWrapper>
      <div
        style={{
          fontSize: t.fontSizes.headerXSmall,
          fontWeight: t.fontWeights.headerSmall,
        }}
      >
        {title}
      </div>
      <div
        style={{
          fontSize: t.fontSizes.headerXSmall,
          fontWeight: t.fontWeights.headerXSmall,
        }}
      >
        {value}
      </div>
    </InfoItemWrapper>
  );
}
