import * as React from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import OverlaySpinner from "../components/Content/Loaders/OverlaySpinner";
import { OverviewSubtitle, OverviewTitle } from "../components/sharedStyles";
import useQuery from "../hooks/useQuery";
import { avyLogin } from "../store/app/actions";
import { addToast } from "../store/toasts";
import * as SC from "./styles";

export default function AvyAuth() {
  const dispatch = useDispatch();

  const query = useQuery();
  let nextUrl = query.get("nexturl");
  const { avyBearerToken } = useParams();

  const [avyAuthFailed, setAvyAuthFailed] = React.useState(false);

  React.useEffect(() => {
    handleLogin();
  }, []);

  const errorCallback = () => {
    setAvyAuthFailed(true);
  };

  const handleLogin = () => {
    if (!avyBearerToken) {
      errorCallback();
    } else {
      dispatch(
        avyLogin(avyBearerToken, errorCallback, nextUrl, () => {
          dispatch(addToast("Inloggning lyckades", "", "SUCCESS"));
        })
      );
    }
  };

  return (
    <SC.LoginPageContainer>
      {!avyAuthFailed && <OverlaySpinner />}
      <SC.LoginContentWrapper>
        <SC.LoginInnerContent></SC.LoginInnerContent>

        <SC.LoginInnerContent>
          {!avyAuthFailed && (
            <OverviewTitle small>Autentiserar...</OverviewTitle>
          )}

          {avyAuthFailed && (
            <>
              <OverviewTitle small>Kunde inte logga in</OverviewTitle>
              <OverviewSubtitle>
                Logga in med ditt Pigello-inlogg{" "}
                <Link to="/login">direkt här</Link>
              </OverviewSubtitle>

              <OverviewSubtitle>
                Token: {avyBearerToken || "Saknas"}
              </OverviewSubtitle>
            </>
          )}
        </SC.LoginInnerContent>
      </SC.LoginContentWrapper>
      <SC.BackgroundImage>
        <SC.BackgroundImageFilter />
      </SC.BackgroundImage>
    </SC.LoginPageContainer>
  );
}
