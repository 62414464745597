import * as SC from "./styles";

export default function TimeLine({ items }) {
  return (
    <SC.TimeLineWrapper>
      {items?.map((i, idx) => (
        <SC.TimeLineItem key={i.date}>
          <SC.TimeLineLineArea>
            {idx > 0 && <SC.TimeLineTopLine />}

            <SC.TimeLineDot />
            {idx < items.length - 1 && <SC.TimeLineBottomLine />}
          </SC.TimeLineLineArea>

          <SC.TimeLineInfoArea>
            <SC.ListItemDate>{i.date}</SC.ListItemDate>
            <SC.ListItemTitle>{i.title}</SC.ListItemTitle>
          </SC.TimeLineInfoArea>
        </SC.TimeLineItem>
      ))}
    </SC.TimeLineWrapper>
  );
}
