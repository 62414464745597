import { useHistory } from "react-router-dom";
import PrimaryButton from "../components/Buttons/PrimaryButton";
import Chapter from "../components/Content/Chapter";
import Hero from "../components/Content/Hero";
import PageTitle from "../components/Content/PageTitle";
import ReportErrandItem from "../components/Content/ReportErrandItem";
import {
  ListItem,
  ListItemDate,
  ListItemTitle,
  ListItemWrapper,
} from "../components/Content/styles";
import { InnerBox } from "../components/sharedStyles";
import useCompanySetting from "../hooks/useCompanySetting";
import useScreenSize from "../hooks/useScreenSize";
import { buildQueryString } from "../store/base";
import { useFilteredReportErrands } from "../store/reportErrands";
import { ChapterWrapper } from "./styles";

export default function Errands() {
  const [setting] = useCompanySetting();
  const { push } = useHistory();

  const { isMobile } = useScreenSize();

  const activeErrandsQ = buildQueryString({
    status__in: [0, 1, 2],
    order_by: ["-created_at"],
    has_no_common_area: true,
  });
  const [activeErrands] = useFilteredReportErrands(activeErrandsQ);

  const doneErrandsQ = buildQueryString({
    status__in: [3, 4],
    order_by: ["-done_time"],
  });
  const [doneErrands] = useFilteredReportErrands(doneErrandsQ);

  const propertyErrandsQ = buildQueryString({
    status_in: [0, 1, 2],
    order_by: ["-created_at"],
    has_no_common_area: false,
  });

  const [propertyErrands] = useFilteredReportErrands(propertyErrandsQ);

  return (
    <>
      {!isMobile && <Hero />}

      <PageTitle
        subtitle={
          "Gå in på ett aktivt ärende för att se uppdateringar och lämna kommentarer"
        }
        renderAction={() => (
          <PrimaryButton
            fillWidth={isMobile}
            extraStyle={{ marginTop: isMobile ? 12 : 0 }}
            clicked={() => push("/create-errand")}
            title="Skapa ny felanmälan"
          />
        )}
      >
        Felanmälan
      </PageTitle>

      {setting?.jour_info && (
        <div
          style={{
            margin: "0px 12px 6px 12px ",
            backgroundColor: "rgba(0, 0, 256, 0.1)",
            borderRadius: 6,
            border: "1px solid blue",
            padding: 12,
          }}
        >
          <div style={{ fontSize: 16, marginBottom: 4 }}>
            Information vid felanmälan
          </div>

          <div style={{ fontSize: 14, fontWeight: 600 }}>
            {setting?.jour_info}
          </div>
        </div>
      )}

      <ChapterWrapper>
        <Chapter title="Mina aktiva ärenden">
          <ListItemWrapper>
            {activeErrands?.map((errand) => (
              <ReportErrandItem errand={errand} key={errand.id} />
            ))}

            {activeErrands?.length === 0 && (
              <ListItem>
                <ListItemTitle>Inga aktiva ärenden</ListItemTitle>
              </ListItem>
            )}
          </ListItemWrapper>
        </Chapter>

        {propertyErrands?.length > 0 && (
          <Chapter title="Aktiva ärenden i min fastighet">
            <ListItemWrapper>
              {propertyErrands?.map((errand) => (
                <ReportErrandItem errand={errand} key={errand.id} />
              ))}

              {propertyErrands?.length === 0 && (
                <ListItem>
                  <ListItemTitle>Inga aktiva ärenden</ListItemTitle>
                </ListItem>
              )}
            </ListItemWrapper>
          </Chapter>
        )}
        {doneErrands?.length > 0 && (
          <Chapter title="Avslutade ärenden">
            <ListItemWrapper>
              {doneErrands?.map((errand) => (
                <ReportErrandItem errand={errand} key={errand.id} />
              ))}

              {doneErrands?.length === 0 && (
                <ListItem>
                  <ListItemTitle>Inga avslutade ärenden</ListItemTitle>
                </ListItem>
              )}
            </ListItemWrapper>
          </Chapter>
        )}
      </ChapterWrapper>
    </>
  );
}
