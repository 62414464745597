import * as React from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../store/base";

import * as SC from "./styles";

export default React.memo(
  ({
    storeName,
    fieldKey,
    method,
    formatDisplayValue,
    isMulti,
    instructionsKey,
    noMargin,
    noTitle,
    noHelp,
    extraStyles,
    disabled,
    filterChoices,
    changeCallback,
    title,
    description,
    forceChoices,
    menuPlacement,

    // allows for react select to anchor the select list to the body.
    // Excellent for overflow problems but MUST NOT be used in modals
    bodyPortaled,
  }) => {
    const dispatch = useDispatch();

    const instructions = useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    });
    const value = useFormInstanceField({ storeName, fieldKey });
    const error = useFormError({ storeName, fieldKey });

    const onChange = (data) => {
      let formattedData = isMulti
        ? []
        : instructions._allowNull
        ? null
        : undefined;
      if (data !== null) {
        if (isMulti) {
          formattedData = data.map((d) => {
            if (d.constructor === Object) {
              return d.v;
            }
            return d;
          });
        } else {
          formattedData = data.constructor === Object ? data.v : data;
        }
      }
      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: formattedData },
        })
      );

      if (changeCallback) {
        changeCallback(data);
      }
    };

    const getDisplayValue = (option) => {
      let obj = undefined;
      if (option.constructor !== Object) {
        const c = forceChoices || instructions._choices;
        obj = c.find((c) => c.v === option);
      } else {
        obj = option;
      }

      if (formatDisplayValue) {
        return formatDisplayValue(obj);
      }
      return obj?.d;
    };

    if (!instructions) {
      return null;
    }
    if (instructions._readOnly) {
      return null;
    }

    const selected = isMulti
      ? value
      : (forceChoices || instructions._choices || []).find(
          (c) => c.v === value
        ) || null;
    const filteredChoices = (
      forceChoices ||
      instructions._choices ||
      []
    ).filter((c) => {
      const preFilteredOut = filterChoices && !filterChoices(c);
      if (preFilteredOut) {
        return false;
      }

      if (isMulti) {
        return !(value || []).includes(c.v);
      } else {
        return value !== c.v;
      }
    });

    return (
      <SC.InputSpacing style={{ ...extraStyles }} {...{ noMargin }}>
        {!noTitle && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <SC.InputFieldTitle>
              {title || instructions._label}
              {instructions._required ? "*" : ""}
            </SC.InputFieldTitle>
          </div>
        )}

        <SC.SelectWrapper>
          <Select
            id={instructions._internalId}
            placeholder="Välj..."
            onChange={onChange}
            defaultValue={isMulti ? [] : null}
            value={selected}
            options={filteredChoices}
            getOptionValue={(option) => option}
            getOptionLabel={getDisplayValue}
            isMulti={isMulti}
            isClearable={instructions?._allowNull}
            isDisabled={disabled}
            {...{ menuPlacement }}
            menuPortalTarget={document.querySelector("body")}
            {...{
              menuPortalTarget: bodyPortaled
                ? document.querySelector("body")
                : null,
            }}
          />
        </SC.SelectWrapper>

        {!!error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
      </SC.InputSpacing>
    );
  }
);
