import * as React from "react";
import { ChapterWrapper } from "../../../views/styles";
import TextButton from "../../Buttons/TextButton";
import { InnerBox } from "../../sharedStyles";
import Chapter from "../Chapter";
import {
  ListItem,
  ListItemDate,
  ListItemTitle,
  ListItemWrapper,
} from "../styles";

export default function SelectSetting({ selectSetting, settings }) {
  React.useEffect(() => {
    if (settings?.length === 1) {
      selectSetting(settings[0]);
    }
  }, [settings, selectSetting]);
  return (
    <>
      <ChapterWrapper>
        {settings?.length > 0 && (
          <Chapter
            extraStyle={{ padding: 0, maxWidth: 450 }}
            title="Typer av felanmälan"
          >
            <ListItemWrapper>
              {settings?.map((setting) => {
                return (
                  <ListItem
                    onClick={() => selectSetting(setting)}
                    key={setting.id}
                  >
                    <ListItemTitle>{setting.str_representation}</ListItemTitle>
                    <ListItemDate>{setting.address?.base || ""}</ListItemDate>
                    <TextButton
                      title="Välj typ av felanmälan"
                      iconType="arrow"
                      iconPlacement="right"
                      extraStyle={{ padding: 0 }}
                      clicked={() => selectSetting(setting)}
                    />
                  </ListItem>
                );
              })}
            </ListItemWrapper>
          </Chapter>
        )}
      </ChapterWrapper>
    </>
  );
}
