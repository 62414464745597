import { ChapterWrapper } from "../../../views/styles";
import YesNoButton from "../../Buttons/YesNoButtons";
import {
  InnerBox,
  OverviewSubtitle,
  OverviewTitle,
  OverviewTitleWithSubtitleWrapper,
  OverviewTitleWrapper,
} from "../../sharedStyles";
import Chapter from "../Chapter";

export default function ChecklistCheck({ onFaultResolved, onFaultRemains }) {
  return (
    <ChapterWrapper>
      <Chapter extraStyle={{ padding: 0 }} title="Resultat av egenkontroll">
        <InnerBox style={{ maxWidth: 450 }}>
          <OverviewTitleWrapper>
            <OverviewTitleWithSubtitleWrapper>
              <OverviewTitle small>Ange om felet avhjälptes</OverviewTitle>
              <OverviewSubtitle>
                Om felet kvarstår skapas i nästa steg en felanmälan. Om felet
                avhjälptes sparas denna egenkontroll för framtida referens.
              </OverviewSubtitle>
            </OverviewTitleWithSubtitleWrapper>
          </OverviewTitleWrapper>
          <YesNoButton
            title="Avhjälptes felet?"
            onYes={onFaultResolved}
            onNo={onFaultRemains}
          />
        </InnerBox>
      </Chapter>
    </ChapterWrapper>
  );
}
