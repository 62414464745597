export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_TENANT",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_TENANT",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_TENANT",

  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_TENANT",
  DESTROY_FORM: "DESTROY_FORM_TENANT",
  SET_FORM_ERROR: "SET_FORM_ERROR_TENANT",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_TENANT",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_TENANT",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_TENANT",
  RESET_STATE: "RESET_STATE_TENANT",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_TENANT",
  REMOVE_OBJECT: "REMOVE_OBJECT_INDUSTRIAL_TENANT",
  CLEAR_FETCHED: "CLEAR_FETCHED_TENANT",

  LIST_URL: "/accounts/tenants/tenant/list/",
  POST_URL: "/accounts/tenants/tenant/",
  PATCH_URL: "/accounts/tenants/tenant/",
  GET_URL: "/accounts/tenants/tenant/",

  STORE_NAME: "tenants",
};
