import useScreenSize from "../../hooks/useScreenSize";
import { OverviewSubtitle, OverviewTitle } from "../sharedStyles";

export default function PageTitle({
  children,
  subtitle,
  subSubtitle,
  renderAction,
}) {
  const { isMobile } = useScreenSize();
  return (
    <div style={{ marginBottom: 12 }}>
      <div
        style={{
          padding: isMobile ? 12 : "12px 24px 12px 12px",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: isMobile ? "flex-start" : "center",
        }}
      >
        <div>
          <OverviewTitle style={{ marginBottom: subtitle ? 6 : 0 }}>
            {children}
          </OverviewTitle>
          {subtitle && (
            <OverviewSubtitle style={{ marginBottom: isMobile ? 0 : 12 }}>
              {subtitle}
            </OverviewSubtitle>
          )}
          {subSubtitle && (
            <OverviewSubtitle style={{ marginBottom: isMobile ? 0 : 12 }}>
              {subSubtitle}
            </OverviewSubtitle>
          )}
        </div>

        {renderAction ? renderAction() : <div></div>}
      </div>
      <hr />
    </div>
  );
}
