import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import OverlaySpinner from "../components/Content/Loaders/OverlaySpinner";
import { OverviewTitle } from "../components/sharedStyles";
import useQuery from "../hooks/useQuery";
import { yourblockLogin } from "../store/app/actions";
import * as SC from "./styles";

export default function YbAuth() {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  let nextUrl = query.get("nexturl");
  const { yourBlockBearerToken } = useParams();
  const [yourBlockAuthFailed, setYourBlockAuthFailed] = React.useState(false);

  const isAuthenticated = useSelector((state) => !!state.app.authorization);
  const authAttemted = useSelector((state) => state.app.hasAttemptedLogin);

  React.useEffect(() => {
    setTimeout(() => {
      console.log("handle login");
      handleLogin();
    }, 500);
  }, []);

  const reroute = () => {
    setTimeout(() => {
      history.push(nextUrl || "/");
    }, 500);
  };

  React.useEffect(() => {
    if (authAttemted) {
      if (isAuthenticated) {
        setTimeout(() => {
          console.log("reroute");
          console.log({ authAttemted });
          console.log({ isAuthenticated });

          reroute();
        }, 500);
      } else if (!yourBlockAuthFailed) {
        console.log("failed");
        console.log({ authAttemted });
        console.log({ isAuthenticated });
        setTimeout(() => {
          console.log("attempting login again");
          handleLogin();
        }, 200);
      }
    }
  }, [authAttemted]);

  const errorCallback = () => {
    console.log("error callback");
    setYourBlockAuthFailed(true);
  };

  const handleLogin = () => {
    if (!yourBlockBearerToken) {
      errorCallback();
    } else {
      dispatch(yourblockLogin(yourBlockBearerToken, errorCallback));
    }
  };

  return (
    <SC.LoginPageContainer>
      {!yourBlockAuthFailed && <OverlaySpinner />}
      <SC.LoginContentWrapper>
        <SC.LoginInnerContent></SC.LoginInnerContent>

        <SC.LoginInnerContent>
          {!yourBlockAuthFailed && (
            <OverviewTitle small>Autentiserar...</OverviewTitle>
          )}

          {yourBlockAuthFailed && (
            <>
              <OverviewTitle small>Försöker igen...</OverviewTitle>
            </>
          )}
        </SC.LoginInnerContent>
      </SC.LoginContentWrapper>
      <SC.BackgroundImage>
        <SC.BackgroundImageFilter />
      </SC.BackgroundImage>
    </SC.LoginPageContainer>
  );
}
