import * as React from "react";
import { useDispatch } from "react-redux";

import * as SC from "./styles";
import { useFilePicker } from "../../hooks/useFilePicker";
import { cloneDeep } from "lodash";
import TextField from "./TextField";
import {
  updateActiveFormInstance,
  useFormError,
  useFormField,
  useFormInstanceField,
} from "../../store/base";
import TextButton from "../Buttons/TextButton";
import OverlaySpinner from "../Content/Loaders/OverlaySpinner";

const MAX_FILE_SIZE = 100;
const ALLOWED_FORMATS = [".png", ".jpg", ".svg"];

export default function ImageM2MContent({
  storeName,
  method,
  fieldKey,
  instructionsKey,
  allowedFormats,
  allowAllFormats,
  title,
  fileKey,
  contentKey,
  manualInstructions,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const usedFileKey = fileKey || "image";

  const { files, onClick, errors, HiddenFileInput } = useFilePicker({
    maxFileSize: MAX_FILE_SIZE,
  });

  const instructions =
    useFormField({
      storeName,
      method,
      fieldKey: instructionsKey || fieldKey,
    }) ?? manualInstructions;
  const value = useFormInstanceField({ storeName, fieldKey });
  const error = useFormError({ storeName, fieldKey });

  React.useEffect(() => {
    handleChange();
  }, [files]);

  const formats = allowedFormats || ALLOWED_FORMATS;

  const removeImage = (id) => {
    let valueCopy = cloneDeep(value);

    valueCopy = valueCopy.filter((v) => v.id !== id && v._referenceId !== id);

    dispatch(
      updateActiveFormInstance({
        storeName,
        data: { [fieldKey]: valueCopy },
      })
    );
  };

  const handleChange = async () => {
    if (files.length === 0) {
      return;
    } else {
      setLoading(true);

      let newFiles = [];
      if (value?.length) {
        newFiles = [...value];
      }

      for (let i = 0; i < files.length; i++) {
        const restFile = await toBase64(files[i]);
        newFiles.push(restFile);
      }

      dispatch(
        updateActiveFormInstance({
          storeName,
          data: { [fieldKey]: newFiles },
        })
      );
      setLoading(false);
    }
  };

  const toBase64 = (file) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file);

      reader.onload = () => {
        const fileData = {
          [usedFileKey]: file.name,
          _tempData: {
            file_name: file.name,
            file_size: file.size,
            data: reader.result.toString(),
          },
          _referenceId: `${file.name}&${file.size}&${Math.floor(
            Math.random() * 100
          )}`,
        };

        resolve(fileData);
      };
    });
  };

  if (!instructions || instructions?._readOnly) {
    return null;
  }

  const displayErrors = () => {
    return error.map((e) => <SC.ErrorMessage>{e?.image[0]}</SC.ErrorMessage>);
  };

  return (
    <SC.InputSpacing>
      <SC.InputFieldTitle>
        {title || instructions._label}
        {instructions._required ? "*" : ""}
      </SC.InputFieldTitle>
      {instructions._helpText && (
        <SC.InputFieldDescription>
          {instructions._helpText}
        </SC.InputFieldDescription>
      )}

      <SC.InputFieldDescription>
        {!allowAllFormats && `Tillåtna filformat: ${formats.join(", ")}.`}{" "}
        Maximal storlek: {MAX_FILE_SIZE}mb.
      </SC.InputFieldDescription>

      <SC.ImageUploaderWrapper>
        {loading && <OverlaySpinner />}
        <SC.UploadedImagesWrapper>
          {value && value.length ? (
            value.map((imageObj, index) => (
              <SC.ImageWithContentWrapper>
                <SC.UploadedImageWithContent
                  key={imageObj.id || imageObj._referenceId}
                  src={
                    imageObj.id
                      ? imageObj[usedFileKey]?.get
                      : imageObj._tempData.data
                  }
                >
                  <SC.RemoveImageButton
                    onClick={() =>
                      removeImage(imageObj.id || imageObj._referenceId)
                    }
                  />
                </SC.UploadedImageWithContent>

                <TextField
                  storeName={storeName}
                  fieldKey={`${fieldKey}[${index}].${contentKey}`}
                  instructionsKey={`${fieldKey}.${contentKey}`}
                  method={method}
                  placeholder="Skriv en beskrivning"
                  noTitle
                  noMargin
                  noBorder
                  noResize
                />
              </SC.ImageWithContentWrapper>
            ))
          ) : (
            <SC.NoImage />
          )}
        </SC.UploadedImagesWrapper>
        <TextButton
          title={
            loading
              ? "Laddar..."
              : value && value.length
              ? "Ladda upp fler bilder"
              : "Ladda upp bilder"
          }
          clicked={onClick}
        />

        <HiddenFileInput
          accept={allowAllFormats ? "image/*" : formats.join(", ")}
          multiple={true}
        />
      </SC.ImageUploaderWrapper>
      {errors?.hasInvalidFileSize && (
        <SC.ErrorMessage>
          En eller fler av filerna är för stora (får vara max {MAX_FILE_SIZE}mb)
        </SC.ErrorMessage>
      )}
      {errors?.hasInvalidImage && (
        <SC.ErrorMessage>
          En eller fler av filerna är inte tillåtna. Välj en ny i ett tillåtet
          format och storlek.
        </SC.ErrorMessage>
      )}
      {error && displayErrors()}
    </SC.InputSpacing>
  );
}
