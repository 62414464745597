import moment from "moment";
export const getReportOverviewStatsProcessName = () => {
  return `REPORT_ERRAND_OVERVIEW_STATS`;
};

export const convertedStatus = ({
  status,
  plannedStart,
  plannedEnd,
  actualStart,
  actualEnd,
}) => {
  const now = moment();
  let start;
  let end;
  let saidStart;
  let saidEnd;
  switch (status) {
    case -1:
      return -1; // aborted
    case 0:
      start = moment(plannedStart);
      end = moment(plannedEnd);

      if ((start.isValid() && now > start) || (end.isValid() && now > end)) {
        return 0; // delayed
      }
      return 1; // not started
    case 1:
      start = moment(plannedStart);
      saidStart = moment(actualStart);
      end = moment(plannedEnd);

      if (end.isValid() && now > end) {
        return 2; // started, delayed end
      } else if (start.isValid() && saidStart.isValid() && saidStart > start) {
        return 3; // started, delayed start
      }

      return 4;
    case 2:
      start = moment(plannedStart);
      saidStart = moment(actualStart);
      end = moment(plannedEnd);

      if (end.isValid() && now > end) {
        return 5; // paused, delayed end
      } else if (start.isValid() && saidStart.isValid() && saidStart > start) {
        return 6; // paused, delayed start
      }

      return 7;
    case 3:
      start = moment(plannedStart);
      saidStart = moment(actualStart);
      end = moment(plannedEnd);
      saidEnd = moment(actualEnd);

      if (end.isValid() && saidEnd.isValid() && saidEnd > end) {
        return 8; // done, delayed end
      } else if (start.isValid() && saidStart.isValid() && saidStart > start) {
        return 9; // done, delayed start
      }

      return 10;
    case 4:
      start = moment(plannedStart);
      saidStart = moment(actualStart);
      end = moment(plannedEnd);
      saidEnd = moment(actualEnd);

      if (end.isValid() && saidEnd.isValid() && saidEnd > end) {
        return 11; // done tenant, delayed end
      } else if (start.isValid() && saidStart.isValid() && saidStart > start) {
        return 12; // done tenant, delayed start
      }

      return 13; // done
    default:
      return 14; // unknown
  }
};

const COLOR_STATUS_MAP = {
  "-1": 10,
  0: 5,
  1: 5,
  2: 4,
  3: 4,
  4: 4,
  5: 3,
  6: 3,
  7: 3,
  8: 0,
  9: 0,
  10: 0,
  11: 0,
  12: 0,
  13: 0,
};
export const getErrandStatus = (errand) => {
  if (!errand)
    return { status: 14, colorStatus: COLOR_STATUS_MAP[14], display: "Okänd" };
  const {
    status,
    planned_start: plannedStart,
    planned_end: plannedEnd,
    execute_start: actualStart,
    execute_end: actualEnd,
  } = errand;
  const actualStatus = convertedStatus({
    status,
    plannedStart,
    plannedEnd,
    actualStart,
    actualEnd,
  });
  const getDisplay = () => {
    switch (actualStatus) {
      case -1:
        return "Avbryten";
      case 0:
        return "Försenad";
      case 1:
        return "Ej påbörjad";
      case 2:
        return "Påbörjad (försenad)";
      case 3:
        return "Påbörjad (försenad)";
      case 4:
        return "Påbörjad";
      case 5:
        return "Pausad (försenad)";
      case 6:
        return "Pausad (försenad)";
      case 7:
        return "Pausad";
      case 8:
        return "Avklarad (försenad)";
      case 9:
        return "Avklarad (försenad)";
      case 10:
        return "Avklarad";
      case 11:
        return "Hyresgäst ansvar (försenad)";
      case 12:
        return "Hyresgäst ansvar (försenad)";
      case 13:
        return "Hyresgäst ansvar";
      default:
        return "Okänd";
    }
  };
  return {
    status: actualStatus,
    colorStatus: COLOR_STATUS_MAP[actualStatus],
    display: getDisplay(),
  };
};
