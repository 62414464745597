import styled, { css } from "styled-components";
import closeIcon from "../../assets/svg/close-circle.svg";
import checkIcon from "../../assets/svg/check_white.svg";
import problemIcon from "../../assets/svg/problem_white.svg";
import infoIcon from "../../assets/svg/info_white.svg";
import { animated } from "react-spring";

import { TOAST_TYPES } from "../../store/toasts";

export const ListWrapper = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  height: 100vh;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  z-index: 5147483600;
  pointer-events: none;
  padding: 0 36px 20px 0;

  @media (max-width: 768px) {
    width: 100vw;
    padding: 0 12px 12px 24px;
  }
`;

export const Toast = styled(animated.div)`
  pointer-events: auto;
  display: flex;
  background-color: white;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 16px;
  box-shadow: ${(p) =>
    p.type === TOAST_TYPES.SUCCESS
      ? p.theme.boxShadows.standardGreen
      : p.type === TOAST_TYPES.ERROR
      ? p.theme.boxShadows.standardRed
      : p.theme.boxShadows.standardBlue};
`;

export const SideColor = styled.div`
  background-color: ${(p) => (p.background ? p.background : "white")};
  width: 50px;
  border-radius: 5px 0 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SideIcon = styled.div`
  width: 28px;
  height: 28px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0);
  background-image: url(${(p) =>
    p.type === TOAST_TYPES.SUCCESS
      ? checkIcon
      : p.type === TOAST_TYPES.ERROR
      ? problemIcon
      : infoIcon});
`;

export const ContentWrapper = styled.div`
  display: flex;
  min-width: 300px;
  flex-direction: column;
  padding: 16px;
`;

export const UpperWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(p) => (p.color ? p.color : "#000")};
  border-radius: 0 8px 8px 0;
`;

export const Title = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  color: ${(p) => p.theme.colors.primaryText};
  text-align: left;
`;

export const Description = styled.div`
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.headerXSmall};
  text-align: left;
  padding-right: 24px;
  max-width: 300px;
`;

export const CloseSign = styled.div`
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin-left: 6px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0);
  background-image: url(${closeIcon});
`;
