import {
  get,
  options,
  destroyForm,
  post,
  patch,
  getPagination,
  getAllFetchProcessName,
  getFiltered,
} from "../../base";
import { getSingleFetchProcessName } from "../../base/utils";
import constants from "./constants";
import { cloneDeep } from "lodash";
import { axiosInstance } from "../../base/store/axios";
import { addToast, TOAST_TYPES } from "../../toasts";

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (
  querystring,
  callback,
  taskToken,
  initiatedBySocket
) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({
    url,
    constants,
    querystring,
    callback,
    taskToken,
    initiatedBySocket,
  });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

const complementedPreProcess = (data, preProcess) => {
  let cloned;
  if (preProcess) {
    cloned = preProcess(data);
  } else {
    cloned = cloneDeep(data);
  }

  // execution_ratings files
  if (!!cloned?.execution_ratings?.length) {
    cloned.execution_ratings.forEach((c) => {
      if (c?.file_temp_data) {
        delete c.file_temp_data;
      } else {
        delete c.file;
      }
    });
  }

  // report_files
  (cloned?.report_files || []).forEach((f) => {
    if (f) {
      // will never be sent to backend
      delete f._tempData;
      delete f._referenceId;

      if (f.id) {
        // file has not been edited, so we can just use the id
        delete f?.file;
      }
    }
  });

  // completion_files
  (cloned?.completion_files || []).forEach((f) => {
    if (f) {
      // will never be sent to backend
      delete f._tempData;
      delete f._referenceId;

      if (f.id) {
        // file has not been edited, so we can just use the id
        delete f?.file;
      }
    }
  });

  // cost files
  (cloned.costs || []).forEach((cf) => {
    if (cf?.evidence && !cf.evidenceData_) {
      delete cf.evidence;
    } else if (cf.evidenceData_) {
      delete cf.evidenceData_;
    }
  });

  // we don't handle this object here
  delete cloned.performer_signature;

  return cloned;
};

export const create = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
}) => {
  const fullPreProcess = (data) => complementedPreProcess(data, preProcess);

  return post({
    url: constants.POST_URL,
    constants,
    preProcess: fullPreProcess,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
  });
};

export const update = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
  preProcess,
}) => {
  const url = `${constants.PATCH_URL}${id}`;
  const fullPreProcess = (data) => complementedPreProcess(data, preProcess);

  return patch({
    url,
    constants,
    preProcess: fullPreProcess,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
  });
};

export const acceptInspectionErrand = ({ inspectionId }) => {
  return async (dispatch) => {
    try {
      const url = `errands/inspection_errand/accept_denie/${inspectionId}/`;
      await axiosInstance.patch(url);

      dispatch({
        type: constants.POST_ACCEPT_DECLINE,
        payload: { id: inspectionId },
      });
    } catch (error) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej acceptera",
          description: "Vänligen försök igen",
        })
      );
    }
  };
};

export const declineInspectionErrand = ({ inspectionId, closedCause }) => {
  return async (dispatch) => {
    try {
      const url = `errands/inspection_errand/accept_denie/${inspectionId}/?decline=true`;
      await axiosInstance.patch(url, {
        closed_cause: closedCause,
      });

      dispatch({
        type: constants.POST_ACCEPT_DECLINE,
        payload: { id: inspectionId },
      });
    } catch (error) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Kunde ej acceptera",
          description: "Vänligen försök igen",
        })
      );
    }
  };
};
