import baseReducer, { INITIAL_STATE } from "../../base/store/reducer";
import constants from "./constants";

const STATE = {
  ...INITIAL_STATE,
  scriveHistory: {},
  hasSignedUp: undefined,
  canUse: undefined,
  hasAttempted: false,
};

export default (state = STATE, action) => {
  const { type, payload } = action;

  return baseReducer(state, action, constants);
};
