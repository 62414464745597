import moment from "moment";

export function initIntercom({ APP_ID, user, intercomData }) {
  initIntercomWidget({ APP_ID, callback: bootIntercom, user, intercomData });
}

function bootIntercom({ APP_ID, user, intercomData }) {
  if (window.Intercom) {
    console.log("[INTERCOM]: f", { user, intercomData });

    window.Intercom("boot", {
      app_id: APP_ID,
      name: user?.str_representation,
      phone: user?.phone,
      email: user?.email,
      user_id: user?.username,
      created_at: moment(intercomData?.user_created_date).unix(),
      user_hash: intercomData?.user_hash_key,
    });
  } else {
    console.log("[INTERCOM]: g");
  }
}

function initIntercomWidget({ APP_ID, callback, user, intercomData }) {
  console.log("[INTERCOM]: c", { intercomData });

  var w = window;
  var ic = w.Intercom;
  if (typeof ic === "function") {
    console.log("[INTERCOM]: e");

    ic("reattach_activator");
    ic("update", w.intercomSettings);
    callback({ APP_ID, user, intercomData });
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      console.log("[INTERCOM]: d");

      var s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://widget.intercom.io/widget/" + APP_ID;
      var x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
      s.addEventListener("load", () =>
        callback({ APP_ID, user, intercomData })
      );
    };
    if (document.readyState === "complete") {
      l();
    } else if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
}
