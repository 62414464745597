import { axiosInstance } from "../base/store/axios";
import { store } from "../store";
import { push } from "connected-react-router";
import constants from "./constants";

export async function getUser(userId) {
  try {
    const { data: user } = await axiosInstance.get(
      `/accounts/users/user/${userId}/`
    );
    return user;
  } catch (e) {
    console.error("Could not fetch user");
  }
}

export function redirect(path) {
  store.dispatch(push(path));
}

export async function getApptusUrls(id) {
  try {
    const result = await axiosInstance.get(
      `${constants.GET_APPTUS_URLS_URL}${id}/`
    );
    return result.data ?? {};
  } catch (error) {
    return {};
  }
}

export async function getRCOUrls(id) {
  try {
    const result = await axiosInstance.get(
      `${constants.GET_RCO_URLS_URL}${id}/`
    );
    return result.data ?? {};
  } catch (error) {
    return {};
  }
}
