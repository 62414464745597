import baseReducer from "../../base/store/reducer";
import constants from "./constants";

const INITIAL_STATE = {
  all: {},
  allCopy: {},
  hasFetchedAll: false,
  inProgress: [],
  forms: {},
  formInstance: {},
  formErrors: {},
  invoiceData: undefined,
  isLoading: false,
  swishLoading: false,
  swishDone: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  const newState = baseReducer(state, action, constants);

  switch (type) {
    case constants.SET_INVOICE_DATA:
      return {
        ...newState,
        invoiceData: payload.data || undefined,
        isLoading: false,
      };
    case constants.SET_LOADING:
      return { ...newState, isLoading: true };
    case constants.SET_PREVIEW_LOADING:
      return { ...newState, [`loading_${payload.actionId}`]: true };
    case constants.SET_INVOICE_PREVIEW_DATA:
      return {
        ...newState,
        [`preview_${payload.actionId}`]: payload.data,
        [`loading_${payload.actionId}`]: false,
      };
    case constants.SET_SWISH_DONE:
      return { ...newState, swishDone: payload.data, swishLoading: false };
    case constants.SET_SWISH_LOADING:
      return { ...newState, swishLoading: true };

    case constants.RESET_STATE:
      return INITIAL_STATE;
    default:
      return newState;
  }
};

export default reducer;
