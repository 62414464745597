export default {
  INSERT_INTO_ALL: "INSERT_INTO_ALL_APARTMENT",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_APARTMENT",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_APARTMENT",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_APARTMENT",
  DESTROY_FORM: "DESTROY_FORM_APARTMENT",
  SET_FORM_ERROR: "SET_FORM_ERROR_APARTMENT",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_APARTMENT",
  INSERT_INTO_PAGINATION: "INSERT_INTO_PAGINATION_APARTMENT",
  INSERT_INTO_FILTERED: "INSERT_INTO_FILTERED_APARTMENT",
  RESET_STATE: "RESET_STATE_APARTMENT",
  SET_ASK_DELETE_DATA: "SET_ASK_DELETE_DATA_APARTMENT",
  REMOVE_OBJECT: "REMOVE_OBJECT_APARTMENT",
  CLEAR_FETCHED: "CLEAR_FETCHED_APARTMENT",

  LIST_URL: "/standard/mainlevel/premises/apartment/list/",
  POST_URL: "/standard/mainlevel/premises/apartment/",
  PATCH_URL: "/standard/mainlevel/premises/apartment/",
  GET_URL: "/standard/mainlevel/premises/apartment/",

  STORE_NAME: "apartment",
};
