import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import TextButton from "../components/Buttons/TextButton";
import Chapter from "../components/Content/Chapter";
import Hero from "../components/Content/Hero";
import InfoItem from "../components/Content/InfoItem";
import PageTitle from "../components/Content/PageTitle";
import { InnerBox } from "../components/sharedStyles";
import useCompanySetting from "../hooks/useCompanySetting";
import { getApptusUrls, getRCOUrls } from "../store/app/services";
import { addToast } from "../store/toasts";
import { ChapterWrapper } from "./styles";

const getTitle = (type) => {
  switch (type) {
    case "aptus":
      return "Aptus";
    case "rco":
      return "RCO";
    default:
      return "";
  }
};

export default function AptusRCOLinks() {
  const dispatch = useDispatch();
  const { goBack, replace } = useHistory();
  const { type } = useParams();
  const [setting] = useCompanySetting();

  const title = getTitle(type);

  const [collectedRCOUrls, setCollectedRCOUrls] = React.useState(null);
  const [collectedApptusUrls, setCollectedApptusUrls] = React.useState(null);

  const performApptusUrlCollection = async () => {
    const data = await getApptusUrls(setting?.id);

    const resultAmount = Object.keys(data).length;
    if (resultAmount === 0) {
      dispatch(
        addToast(
          "Kunde ej generera länk till Aptus",
          "Vänligen försök igen",
          "ERROR"
        )
      );
      setCollectedApptusUrls(null);
      // close and temrinage
    } else if (resultAmount === 1) {
      window.open(Object.values(data)[0], "_blank");
      setCollectedApptusUrls(null);
    } else {
      setCollectedApptusUrls(data);
    }
  };

  const performRCOUrlCollection = async () => {
    const data = await getRCOUrls(setting?.id);

    const resultAmount = Object.keys(data).length;
    if (resultAmount === 0) {
      dispatch(
        addToast(
          "Kunde ej generera länk till RCO",
          "Vänligen försök igen",
          "ERROR"
        )
      );
      setCollectedRCOUrls(null);
      // close and temrinage
    } else if (resultAmount === 1) {
      window.open(Object.values(data)[0], "_blank");
      setCollectedRCOUrls(null);
    } else {
      setCollectedRCOUrls(data);
    }
  };

  React.useEffect(() => {
    if (!setting) return;
    if (type === "aptus" && !collectedApptusUrls) {
      performApptusUrlCollection();
    } else if (type === "rco" && !collectedRCOUrls) {
      performRCOUrlCollection();
    }
  }, [type, setting]);

  return (
    <>
      <Hero />

      <TextButton
        extraStyle={{ marginTop: 12 }}
        iconType="arrow-back"
        title="Gå tillbaka"
        clicked={() => goBack()}
      />

      <PageTitle>{title}</PageTitle>

      <ChapterWrapper>
        <Chapter title="Välj objekt/identitet att använda">
          <InnerBox>
            {collectedApptusUrls != null &&
              Object.keys(collectedApptusUrls).map((key) => {
                return (
                  <div key={key}>
                    <InfoItem
                      title={key}
                      value={
                        <TextButton
                          title="Logga in"
                          iconType="arrow"
                          clicked={() => {
                            window
                              .open(collectedApptusUrls[key], "_blank")
                              .focus();
                            replace("/");
                          }}
                        />
                      }
                    />
                  </div>
                );
              })}

            {collectedRCOUrls != null &&
              Object.keys(collectedRCOUrls).map((key) => {
                return (
                  <div key={key}>
                    <InfoItem
                      title={key}
                      value={
                        <TextButton
                          title="Logga in"
                          iconType="arrow"
                          clicked={() => {
                            window
                              .open(collectedRCOUrls[key], "_blank")
                              .focus();
                            replace("/");
                          }}
                        />
                      }
                    />
                  </div>
                );
              })}
          </InnerBox>
        </Chapter>
      </ChapterWrapper>
    </>
  );
}
