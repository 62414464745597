import * as React from "react";
import useCompanySetting from "../../hooks/useCompanySetting";
import useScreenSize from "../../hooks/useScreenSize";
import * as SC from "./styles";
export default function Hero() {
  const [setting, settingLoading] = useCompanySetting();
  const { isMobile } = useScreenSize();
  return (
    <SC.HeroWrapper url={setting?.hello_image?.get}>
      {isMobile && <SC.CustomerLogo url={setting?.logo?.get} />}
    </SC.HeroWrapper>
  );
}
