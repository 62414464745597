import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import * as SC from "./styles";
// style, design
import { login } from "../store/app/actions";
import { resetPassword } from "../store/users";
import useQuery from "../hooks/useQuery";
// import Logo from "../../components/Layout/Sidebar/Logo";
import NonConnectedTextInput from "../components/Form/NonConnectedTextInput";

import OverlaySpinner from "../components/Content/Loaders/OverlaySpinner";
import { addToast, TOAST_TYPES } from "../store/toasts";
import TextButton from "../components/Buttons/TextButton";
import PrimaryButton from "../components/Buttons/PrimaryButton";
import { OverviewSubtitle, OverviewTitle } from "../components/sharedStyles";
import { CustomerLogo } from "../components/Content/styles";
import useScreenSize from "../hooks/useScreenSize";
import useAnonCompanyData from "../hooks/useAnonCompanyData";
import theme from "../theme";

const STEPS = {
  LOGIN: "LOGIN",
  RESET_PASSWORD: "RESET_PASSWORD",
  DID_RESET: "DID_RESET",
  MFA: "MFA",
};

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [step, setStep] = React.useState(STEPS.LOGIN);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [resetEmail, setResetEmail] = React.useState("");

  const { companyKey } = useParams();

  const [anonCompanyData, anonCompanyDataLoading] = useAnonCompanyData();

  const { isMobile } = useScreenSize();

  const query = useQuery();

  const showLoggedOutBanner = query.get("loggedout");
  const next = query.get("next");

  const errorStatus = useSelector((state) => state.app.errorStatus);
  const isAuthenticated = useSelector((state) => !!state.app.authorization);
  const authenticating = useSelector((state) => state.app.authenticating);

  const onError = React.useCallback(() => {
    if (errorStatus) {
      dispatch(
        addToast({
          type: TOAST_TYPES.ERROR,
          title: "Felaktiga uppgifter",
          description:
            "Du har skrivit in ett felaktigt användarnamn eller lösenord.",
        })
      );
    }
  }, [errorStatus]);

  React.useEffect(() => onError(), [onError]);

  const handleResetPassword = () => {
    dispatch(resetPassword({ username, email: resetEmail }));
    setStep(STEPS.DID_RESET);
  };
  const handleLogin = () => {
    dispatch(login(username, password, next));
  };

  React.useEffect(() => {
    if (showLoggedOutBanner) {
      dispatch(
        addToast({
          type: TOAST_TYPES.INFO,
          title: "Du loggades ut",
        })
      );
    }
  }, []);

  React.useEffect(() => {
    if (isAuthenticated) {
      history.push(next && !next.includes("invitation") ? next : "/");
    }
  }, [history, isAuthenticated]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (step === STEPS.RESET_PASSWORD) {
        handleResetPassword();
      } else {
        handleLogin();
      }
    }
  };

  if (anonCompanyDataLoading) {
    return (
      <>
        <OverlaySpinner />
      </>
    );
  }

  return (
    <>
      <SC.LoginPageContainer>
        {authenticating && <OverlaySpinner />}
        <SC.LoginContentWrapper>
          <SC.LoginInnerContent>
            <CustomerLogo url={anonCompanyData?.logo?.get} />
          </SC.LoginInnerContent>

          {step === STEPS.LOGIN && (
            <SC.LoginInnerContent>
              <OverviewTitle small>Välkommen till Mina sidor</OverviewTitle>

              <OverviewSubtitle
                style={{
                  textAlign: "center",
                  maxWidth: "60%",
                  marginBottom: 24,
                }}
              >
                Logga in med dina inloggningsuppgifter
              </OverviewSubtitle>

              <NonConnectedTextInput
                extraStyles={{
                  minWidth: isMobile ? "80%" : "60%",
                  marginBottom: 12,
                }}
                value={username}
                onChange={(val) => setUsername(val)}
                label="Användarnamn"
                onKeyPress={handleKeyPress}
              />
              <NonConnectedTextInput
                type="password"
                extraStyles={{
                  minWidth: isMobile ? "80%" : "60%",
                  marginBottom: 12,
                }}
                value={password}
                onChange={(val) => setPassword(val)}
                label="Lösenord"
                onKeyPress={handleKeyPress}
              />

              <PrimaryButton
                extraStyle={{
                  minWidth: isMobile ? "80%" : "60%",
                  backgroundColor: anonCompanyData
                    ? `#${anonCompanyData?.color_code}`
                    : theme().colors.primaryBlue,
                }}
                title="Logga in"
                clicked={handleLogin}
              />
              <TextButton
                extraStyle={{ marginTop: 12 }}
                title="Glömt lösenord?"
                clicked={() => setStep(STEPS.RESET_PASSWORD)}
              />
            </SC.LoginInnerContent>
          )}

          {step === STEPS.RESET_PASSWORD && (
            <SC.LoginInnerContent>
              <NonConnectedTextInput
                extraStyles={{
                  minWidth: isMobile ? "80%" : "60%",
                  marginBottom: 12,
                }}
                value={resetEmail}
                onChange={(val) => setResetEmail(val)}
                label="Din E-postadress hos Pigello"
                onKeyPress={handleKeyPress}
              />
              <NonConnectedTextInput
                extraStyles={{
                  minWidth: isMobile ? "80%" : "60%",
                  marginBottom: 12,
                }}
                value={username}
                onChange={(val) => setUsername(val)}
                label="Ditt användarnamn hos Pigello"
                onKeyPress={handleKeyPress}
              />

              <PrimaryButton
                extraStyle={{ minWidth: isMobile ? "80%" : "60%" }}
                title="Återställ lösenord"
                clicked={handleResetPassword}
              />
              <TextButton
                extraStyle={{ marginTop: 12 }}
                title="Tillbaka till inloggning"
                clicked={() => setStep(STEPS.LOGIN)}
              />
            </SC.LoginInnerContent>
          )}

          {step === STEPS.DID_RESET && (
            <SC.LoginInnerContent>
              <OverviewTitle small>Kolla din E-post</OverviewTitle>

              <OverviewSubtitle
                style={{
                  textAlign: "center",
                  maxWidth: isMobile ? "80%" : "60%",
                }}
              >
                Om vi hittade en användare som matchar de uppgifterna du angav
                så har vi skickat en återställningslänk.
              </OverviewSubtitle>

              <PrimaryButton
                extraStyle={{ marginTop: 12 }}
                title="Tillbaka till inloggning"
                clicked={() => setStep(STEPS.LOGIN)}
              />
            </SC.LoginInnerContent>
          )}
        </SC.LoginContentWrapper>
        <SC.BackgroundImage url={anonCompanyData?.hello_image?.get}>
          <SC.BackgroundImageFilter />
        </SC.BackgroundImage>
      </SC.LoginPageContainer>
    </>
  );
}
