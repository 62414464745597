export default {
  SET_INVOICE_DATA: "SET_INVOICE_DATA",
  SET_INVOICE_PREVIEW_DATA: "SET_INVOICE_PREVIEW_DATA",
  SET_SWISH_DONE: "SET_SWISH_DONE",
  RESET_STATE: "RESET_STATE_INVOICE",
  SET_LOADING: "SET_LOADING_INVOICE",
  SET_SWISH_LOADING: "SET_SWISH_LOADING",
  SET_PREVIEW_LOADING: "SET_PREVIEW_LOADING_INVOICEING",

  INSERT_INTO_ALL: "INSERT_INTO_ALL_TENANT_INVOICING",
  ADD_TO_IN_PROGRESS: "ADD_TO_IN_PROGRESS_TENANT_INVOICING",
  REMOVE_FROM_IN_PROGRESS: "REMOVE_FROM_IN_PROGRESS_TENANT_INVOICING",
  INSERT_INTO_FORMS: "INSERT_INTO_FORMS_TENANT_INVOICING",
  DESTROY_FORM: "DESTROY_FORM_TENANT_INVOICING",
  SET_FORM_ERROR: "SET_FORM_ERROR_TENANT_INVOICING",
  UPDATE_FORM_INSTANCE: "UPDATE_FORM_INSTANCE_TENANT_INVOICING",

  GET_INVOICE_URL: "/accounting/debtor/tenant_invoices/",
  GET_INVOICE_PREVIEW_URL: "/accounting/debtor/tenant_invoice_preview/",
  INITIATE_SWISH_URL: "/accounting/tenant_swish/",

  LIST_URL: "/accounting/debtor/debtor_invoice_config/list/",
  POST_URL: "/accounting/debtor/debtor_invoice_config/",
  PATCH_URL: "/accounting/debtor/debtor_invoice_config/",
  GET_URL: "/accounting/debtor/debtor_invoice_config/",

  STORE_NAME: "tenant_invoicing",
};
