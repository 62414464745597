import Cookies from "js-cookie";
import { currentEnv, ENVIRONMENTS } from "../index";

export const setIsAvyAuth = (isAvyAuth) => {
  localStorage.setItem("is_avy_auth", isAvyAuth);
};
export const getIsAvyAuth = () => {
  return localStorage.getItem("is_avy_auth") === "true";
};

export const setToken = (token) => {
  Cookies.set(
    "auth",
    token,
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};

export const setMgmtToken = (token) => {
  Cookies.set(
    "management_auth",
    token,
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};

export const setUserId = (userId) => {
  Cookies.set(
    "user_id",
    userId,
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};

export const setMgmtUserId = (userId) => {
  Cookies.set(
    "management_user_id",
    userId,
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};

export const setIntercomHashKey = (hashKey) => {
  Cookies.set(
    "intercom_hash_key",
    hashKey,
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};

export const setMgmtIntercomHashKey = (hashKey) => {
  Cookies.set(
    "management_intercom_hash_key",
    hashKey,
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};

export const setUserCreatedDate = (createdDate) => {
  Cookies.set(
    "intercom_user_created_at",
    createdDate,
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};

export const setMgmtUserCreatedDate = (createdDate) => {
  Cookies.set(
    "management_intercom_user_created_at",
    createdDate,
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};

export const setCustomerData = (customer) => {
  Cookies.set(
    "intercom_customer",
    JSON.stringify(customer),
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};

export const setTenantPortalSetting = (id) => {
  Cookies.set(
    "tenant_portal_setting",
    JSON.stringify(id),
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};
export const setMgmtCustomerData = (customer) => {
  Cookies.set(
    "management_intercom_customer",
    JSON.stringify(customer),
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};

export const getToken = () => {
  const token = Cookies.get("auth");
  // const token = 'afe0052af6bb2fbd9fa415e20da9e12de4e20d5c';

  if (token) {
    return { token };
  }

  return { token: null };
};

export const getId = () => {
  const userIdString = Cookies.get("user_id");

  if (userIdString) {
    return parseInt(userIdString);
  }

  return null;
};

export const getIntercomHashKey = () => {
  const hashKey = Cookies.get("intercom_hash_key");

  if (hashKey) {
    return hashKey;
  }

  return null;
};

export const getUserCreatedDate = () => {
  const userCreatedDate = Cookies.get("intercom_user_created_at");

  if (userCreatedDate) {
    return userCreatedDate;
  }

  return null;
};

export const getCustomer = () => {
  const customer = Cookies.get("intercom_customer");

  if (customer && customer != "undefined") {
    return JSON.parse(customer);
  }

  return null;
};

export const getTenantPortalSetting = () => {
  const id = Cookies.get("tenant_portal_setting");

  if (id && id != "undefined") {
    return JSON.parse(id);
  }

  return null;
};

export const clearUserData = () => {
  Cookies.remove(
    "auth",
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
  Cookies.remove(
    "user_id",
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
  Cookies.remove(
    "intercom_user_created_at",
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
  Cookies.remove(
    "intercom_customer",
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
  Cookies.remove(
    "intercom_hash_key",
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
  Cookies.remove(
    "tenant_portal_setting",
    currentEnv === ENVIRONMENTS.DEV ? undefined : { domain: ".pigello.se" }
  );
};

export const formattedToken = () => {
  const { token } = getToken();

  if (token == null) {
    return null;
  }

  return `Token ${token}`;
};

export const setIntercomData = (data) => {
  localStorage.setItem("intercom_data", JSON.stringify(data));
};

export const getIntercomData = () => {
  try {
    const d = localStorage.getItem("intercom_data");
    if (d) {
      return JSON.parse(d || {});
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};
