import * as React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { buildQueryString } from "../../store/base";
import { useNotificationsPagination } from "../../store/notifications";
import TextButton from "../Buttons/TextButton";
import * as SC from "./styles";
import { useHistory } from "react-router-dom";
import { InnerBox } from "../sharedStyles";
import Notification from "./Notification";

export default function Notifications() {
  const userId = useSelector((state) => state.app.userId);
  const [page, setPage] = React.useState(1);
  const [hasNextPage, setHasNextPage] = React.useState(false);

  const pageSize = 5;

  const query = buildQueryString({
    order_by: ["-created_at"],
    _page: page,
    not_read_by__id: userId,
    _page_size: pageSize,
  });

  const { push } = useHistory();

  const [notifications, notificationsLoading] =
    useNotificationsPagination(query);

  React.useEffect(() => {
    if (!!notifications?.next != hasNextPage) {
      setHasNextPage(!!notifications?.next);
    }
  }, [notifications?.next]);

  return (
    <SC.NewsWrapper>
      {notifications?.results?.map((n) => (
        <Notification notification={n} key={n.id} />
      ))}

      {notifications?.results?.length === 0 && (
        <SC.NewsItem>
          <SC.NewsTitle>Inga nya notifikationer</SC.NewsTitle>
        </SC.NewsItem>
      )}

      <TextButton
        title="Visa alla notiser"
        iconType="arrow"
        iconPlacement="right"
        clicked={() => push("/notifications")}
      />
    </SC.NewsWrapper>
  );
}
