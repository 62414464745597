import {
  get,
  options,
  destroyForm,
  post,
  patch,
  getPagination,
  getAllFetchProcessName,
  getFiltered,
} from "../../base";
import { getSingleFetchProcessName } from "../../base/utils";
import constants from "./constants";
import { cloneDeep } from "lodash";

export const getAll = () => {
  return get({
    url: constants.LIST_URL,
    constants,
    name: getAllFetchProcessName(),
  });
};

export const getSingle = (id) => {
  const url = `${constants.GET_URL}${id}`;
  return get({ url, constants, name: getSingleFetchProcessName(id) });
};

export const performFilter = (
  querystring,
  callback,
  taskToken,
  initiatedBySocket
) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getFiltered({
    url,
    constants,
    querystring,
    callback,
    taskToken,
    initiatedBySocket,
  });
};

export const filterPagination = (querystring) => {
  const url = `${constants.LIST_URL}?${querystring}`;
  return getPagination({ url, constants, querystring });
};

export const getPostForm = () => {
  return options({ url: constants.POST_URL, constants, method: "POST" });
};

export const destroyPostForm = (success) => {
  return destroyForm({ constants, method: "POST", success });
};

export const getPatchForm = (id) => {
  const url = `${constants.PATCH_URL}${id}`;
  return options({ url, constants, method: "PATCH" });
};

export const destroyPatchForm = (success) => {
  return destroyForm({ constants, method: "PATCH", success });
};

const preProcess = (data) => {
  let cloned;

  cloned = cloneDeep(data);

  if (!cloned?.user?.image_temp_data) {
    // if file has not been edited, remove image url
    delete cloned?.user?.image;

    return cloned;
  } else {
    // if file has been edited, delete b64 to avoid sending large unnescessary data to backend
    // forminstance keeps the data until upload finished
    delete cloned.user.image_temp_data;
  }

  return cloned;
};

export const create = ({
  processSuccess,
  processError,
  successCallback,
  errorCallback,
}) => {
  return post({
    url: constants.POST_URL,
    constants,
    processSuccess,
    processError,
    successCallback,
    errorCallback,
  });
};

export const update = ({
  id,
  processSuccess,
  processError,
  successCallback,
  errorCallback,
}) => {
  const url = `${constants.PATCH_URL}${id}`;

  return patch({
    url,
    constants,
    processSuccess,
    processError,
    successCallback,
    preProcess,
    errorCallback,
  });
};
