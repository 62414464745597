import * as React from "react";
import * as SC from "./styles";
import { useHistory, useLocation } from "react-router-dom";
import notifIcon from "../../assets/svg/notifications_black.svg";
import homeIcon from "../../assets/svg/home_black.svg";
import paymentLogo from "../../assets/svg/payments_black.svg";
import contractLogo from "../../assets/svg/contract_black.svg";
import apartmentLogo from "../../assets/svg/apartment_black.svg";

import infoLogo from "../../assets/svg/info_black.svg";
import lockLogo from "../../assets/svg/lock_red.svg";
import inspectionLogo from "../../assets/svg/inspection_black.svg";

import workerLogo from "../../assets/svg/worker_black.svg";
import useScreenSize from "../../hooks/useScreenSize";
import { CustomerLogo } from "../Content/styles";
import theme from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/app/actions";
import useCompanySetting from "../../hooks/useCompanySetting";
import { useNotificationsPaginationCount } from "../../store/notifications";
import { useLeaseContractPaginationCount } from "../../store/leaseContracts/hooks/retrieve";
import { useOtherContractPaginationCount } from "../../store/otherContracts/hooks/retrieve";
import { useParkingContractPaginationCount } from "../../store/parkingContracts/hooks/retrieve";
import { useInspectionErrandsPaginationCount } from "../../store/inspectionErrands";

export default function Navbar() {
  const dispatch = useDispatch();
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const { isMobile } = useScreenSize();
  const { push } = useHistory();

  const [setting, settingLoading] = useCompanySetting();
  const { pathname } = useLocation();

  const hideErrands = setting?.hide_errand_handling;

  const isAvyAuth = useSelector((state) => state.app.isAvyAuth);

  const [unreadNotificationsCount] = useNotificationsPaginationCount({
    filters: {
      read_by_me: false,
    },
  });
  const [leaseContractCount] = useLeaseContractPaginationCount({});
  const [otherContractCount] = useOtherContractPaginationCount({});
  const [parkingContractCount] = useParkingContractPaginationCount({});
  const [inspectionsCount] = useInspectionErrandsPaginationCount({});

  const hasContracts =
    leaseContractCount > 0 ||
    otherContractCount > 0 ||
    parkingContractCount > 0;

  const hasInspections = inspectionsCount > 0;

  const user = useSelector((state) => state.app.user);

  const userName = `${user?.first_name ? `${user?.first_name}+` : ""}${
    user?.last_name ? `${user?.last_name}` : ""
  }`;

  const location = useLocation();

  React.useEffect(() => {
    setMobileMenuOpen(false);
  }, [location]);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      {isMobile && (
        <SC.MobileMenu isOpen={mobileMenuOpen}>
          <SC.MobileMenuTopBar>
            <CustomerLogo
              url={setting?.logo?.get}
              style={{ marginRight: "auto" }}
            />
            <SC.CloseButton onClick={() => setMobileMenuOpen(false)} />
          </SC.MobileMenuTopBar>

          {!isAvyAuth && (
            <SC.MenuItem active={pathname === "/"} to="/" icon={homeIcon}>
              Hem
            </SC.MenuItem>
          )}
          {!hideErrands && (
            <>
              {" "}
              <SC.MenuItem
                active={pathname.includes("/errands")}
                to="/errands"
                icon={workerLogo}
              >
                Felanmälan
              </SC.MenuItem>
              {hasInspections && (
                <SC.MenuItem
                  active={pathname.includes("/inspections")}
                  to="/inspections"
                  icon={inspectionLogo}
                >
                  Besiktningar
                </SC.MenuItem>
              )}
            </>
          )}
          {!isAvyAuth && (
            <SC.MenuItem
              active={pathname.includes("/invoices")}
              to="/invoices"
              icon={paymentLogo}
            >
              Avier och betalningar
            </SC.MenuItem>
          )}
          <SC.MenuItem
            active={pathname.includes("/objects")}
            to="/objects"
            icon={apartmentLogo}
          >
            Mina objekt
          </SC.MenuItem>
          {hasContracts && (
            <SC.MenuItem
              active={pathname.includes("/contracts")}
              to="/contracts"
              icon={contractLogo}
            >
              Mina avtal
            </SC.MenuItem>
          )}
          {!isAvyAuth && (
            <SC.MenuItem
              active={pathname.includes("/documents-and-info")}
              to="/documents-and-info"
              icon={infoLogo}
            >
              Dokument och info
            </SC.MenuItem>
          )}
          {/* <SC.MenuItem
            active={pathname.includes("/bookings")}
            to="/bookings"
            icon={watchLogo}
          >
            Bokningar
          </SC.MenuItem>
          <SC.MenuItem
            active={pathname.includes("/rentals")}
            to="/rentals"
            icon={secondHandBlackLogo}
          >
            Andrahandsuthyrning
          </SC.MenuItem>
          <SC.MenuItem
            active={pathname.includes("/brfcompany")}
            to="/brfcompany"
            icon={gavelLogo}
          >
            Min förening
          </SC.MenuItem> */}
          {!isAvyAuth && (
            <SC.MenuItem
              onClick={handleLogout}
              style={{ color: theme().colors.red, marginTop: 20 }}
              icon={lockLogo}
            >
              Logga ut
            </SC.MenuItem>
          )}
        </SC.MobileMenu>
      )}

      <SC.Wrapper isMobile={isMobile}>
        {isMobile && (
          <SC.BurgerButton onClick={() => setMobileMenuOpen(true)} />
        )}

        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}
        >
          <SC.ProfileBadgeWrapper
            onClick={() => push("/notifications")}
            style={{ marginRight: 4 }}
          >
            <SC.ProfileBadgeTitle>Notiser</SC.ProfileBadgeTitle>

            <SC.IconButton
              icon={notifIcon}
              notReadCount={unreadNotificationsCount}
            />
          </SC.ProfileBadgeWrapper>

          <SC.ProfileBadgeWrapper onClick={() => push("/profile")}>
            <SC.ProfileBadgeTitle>Min profil</SC.ProfileBadgeTitle>
            <SC.ProfileBadge name={userName} />
          </SC.ProfileBadgeWrapper>
        </div>
      </SC.Wrapper>
    </>
  );
}
