import { axiosInstance } from "../../base/store/axios";
import constants from "./constants";

export async function getTenantInvoiceData() {
  const { data: invoiceData } = await axiosInstance.get(
    constants.GET_INVOICE_URL
  );
  if (invoiceData) {
    return invoiceData;
  } else return null;
}

export async function getTenantInvoicePreview(actionId) {
  try {
    const { data: previewData } = await axiosInstance.get(
      `${constants.GET_INVOICE_PREVIEW_URL}${actionId}/`
    );
    if (previewData) {
      return previewData;
    } else {
      return null;
    }
  } catch (e) {
    console.error("Could not fetch invoice preview data");
  }
}

export async function initiateSwishPayment(actionId, phoneNumber) {
  try {
    const { data: swishResponse } = await axiosInstance.get(
      `${constants.INITIATE_SWISH_URL}${actionId}/${phoneNumber}/`
    );
    if (swishResponse) {
      return swishResponse;
    } else {
      return null;
    }
  } catch (e) {
    console.error(e, "could not initiate swish payment");
  }
}
