import styled, { css } from "styled-components";

import downloadIcon from "../../assets/svg/download_link.svg";
import materialAddIcon from "../../assets/svg/add-blue.svg";
import materialDoneIcon from "../../assets/svg/done_blue.svg";
import materialArrowIcon from "../../assets/svg/arrow-right-blue.svg";
import materialWhiteArrowIcon from "../../assets/svg/arrow-right-white.svg";
import materialEdit from "../../assets/svg/edit-blue.svg";
import infoBlue from "../../assets/svg/info_blue.svg";
import closeWhite from "../../assets/svg/close-circle-white.svg";
import closeIcon from "../../assets/svg/close-circle-blue.svg";
import moreIcon from "../../assets/svg/more_vertical_black.svg";
import moreIconWhite from "../../assets/svg/more_vertical_white.svg";
import notificationsIcon from "../../assets/svg/notifications_blue.svg";
import pauseIcon from "../../assets/svg/pause_blue.svg";
import copyIcon from "../../assets/svg/copy_blue.svg";
import syncIcon from "../../assets/svg/sync_blue.svg";
import eventRepeatIcon from "../../assets/svg/event_repeat_blue.svg";
import eventIcon from "../../assets/svg/calendar_blue.svg";
import launchIcon from "../../assets/svg/fullscreen_blue.svg";
import launchIconWhite from "../../assets/svg/fullscreen_white.svg";
import lockIconBlue from "../../assets/svg/lock_blue.svg";
import lockIconRed from "../../assets/svg/lock_red.svg";
import starIconBlue from "../../assets/svg/star_blue.svg";
import gradeIconBlue from "../../assets/svg/grade_blue.svg";
import receiptIconBlue from "../../assets/svg/receipt_blue.svg";
import watchIconBlue from "../../assets/svg/watch_blue.svg";

export const PrimaryButton = styled.button`
  position:relative;
  -webkit-appearance: none;
  white-space:nowrap;
  border: none;
  box-sizing: border-box;
  color: ${(p) => (p.inverted ? p.theme.colors.primaryBlue : "#fff")};
  cursor: ${(p) => (p.disabled ? "not-allowed !important" : "pointer")};
  display: inline-block;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 30px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  box-shadow: ${(p) => p.theme.boxShadows.highlight};
  font-family: "Inter", sans-serif;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.data};
  background-color: ${(p) =>
    p.inverted ? "#fff" : p.theme.colors.primaryBlue};
  border-radius: 8px;


  &:hover {
    background-color: ${(p) =>
      p.inverted ? p.theme.colors.gray1 : p.theme.colors.primaryBlueFaded};   
  }
  
  &:active {
    outline: none;
  }

  ${
    "" /* &.full-width {
    width: 100%;
  }
  &.warn {
    background-color: red;
  } */
  }
  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.7;
    `}
  }

   ${(p) =>
     p.fillWidth &&
     css`
       width: 100%;
     `}
}
`;

export const SecondaryButton = styled.button`
background-color: #fff;
white-space:nowrap;
  -webkit-appearance: none;
  border: 1px solid ${(p) => p.theme.colors.primaryBlue};
  box-sizing: border-box;
  cursor: ${(p) => (p.disabled ? "not-allowed !important" : "pointer")};

  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 30px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  border-radius: 30px;
  color: ${(p) => p.theme.colors.primaryBlue};
  
  &:hover {
    color: ${(p) => p.theme.colors.primaryBlueFaded};
  }

  &:active {
    outline: none;
  }

  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.7;
    `}
  }


  ${(p) =>
    p.fillWidth &&
    css`
      width: 100%;
    `}
}

`;

export const TextButton = styled.button`
  -webkit-appearance: none;
  box-sizing: border-box;
  cursor: ${(p) => (p.disabled ? "not-allowed !important" : "pointer")};
  font-family: "Inter", sans-serif;
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  font-weight: ${(p) => p.theme.fontWeights.data};
  color: ${(p) =>
    p.disabled
      ? p.theme.colors.gray2
      : p.inverted
      ? "#fff"
      : p.theme.colors.primaryBlue};
  display: inline-flex;
  outline: none;
  text-align: left;
  transition: all 0.2s ease-in-out;
  position: relative;
  align-items: center;

  ${(p) =>
    p.iconPlacement === "right" &&
    css`
      flex-direction: row-reverse;
      margin-right: auto;
    `}

  ${(p) =>
    p.underlined &&
    css`
      text-decoration: underline;
    `}


  ${(p) =>
    p.red &&
    css`
      color: ${(p) => p.theme.colors.red};
    `}

  &:hover {
    color: ${(p) => p.theme.colors.primaryBlueFaded};
  }
`;

export const TextButtonIcon = styled.div`
  height: ${(p) => p.theme.fontSizes.headerXSmall};
  width: ${(p) => p.theme.fontSizes.headerXSmall};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 3px 0 0;

  ${(p) =>
    p.iconPlacement === "right" &&
    css`
      margin: 0 0 0 3px;
    `}

  ${"" /* standard icon types, string passed down  */}
  ${(p) =>
    p.iconType === "download" &&
    css`
      background-image: url(${downloadIcon});
    `}
    ${(p) =>
    p.iconType === "add" &&
    css`
      background-image: url(${materialAddIcon});
    `}
    ${(p) =>
    p.iconType === "arrow" &&
    css`
      background-image: url(${p.inverted
        ? materialWhiteArrowIcon
        : materialArrowIcon});
    `}

 ${(p) =>
    p.iconType === "arrow-back" &&
    css`
      background-image: url(${materialArrowIcon});
      transform: rotate(180deg);
    `}

     ${(p) =>
    p.iconType === "edit" &&
    css`
      background-image: url(${materialEdit});
    `}

     ${(p) =>
    p.iconType === "close" &&
    css`
      background-image: url(${p.inverted ? closeWhite : closeIcon});
    `}

     ${(p) =>
    p.iconType === "done" &&
    css`
      background-image: url(${materialDoneIcon});
    `}

     ${(p) =>
    p.iconType === "info" &&
    css`
      background-image: url(${infoBlue});
    `}
      
    ${(p) =>
    p.iconType === "notification" &&
    css`
      background-image: url(${notificationsIcon});
    `}

       
    ${(p) =>
    p.iconType === "pause" &&
    css`
      background-image: url(${pauseIcon});
    `}

      ${(p) =>
    p.iconType === "copy" &&
    css`
      background-image: url(${copyIcon});
    `}

        ${(p) =>
    p.iconType === "sync" &&
    css`
      background-image: url(${syncIcon});
    `}

         ${(p) =>
    p.iconType === "eventrepeat" &&
    css`
      background-image: url(${eventRepeatIcon});
    `}
         ${(p) =>
    p.iconType === "receipt" &&
    css`
      background-image: url(${receiptIconBlue});
    `}
         ${(p) =>
    p.iconType === "watch" &&
    css`
      background-image: url(${watchIconBlue});
    `}

          ${(p) =>
    p.iconType === "event" &&
    css`
      background-image: url(${eventIcon});
    `}

          ${(p) =>
    p.iconType === "star" &&
    css`
      background-image: url(${starIconBlue});
    `}

          ${(p) =>
    p.iconType === "grade" &&
    css`
      background-image: url(${gradeIconBlue});
    `}

        ${(p) =>
    p.iconType === "launch" &&
    !p.inverted &&
    css`
      background-image: url(${launchIcon});
    `}

       ${(p) =>
    p.iconType === "launch" &&
    p.inverted &&
    css`
      background-image: url(${launchIconWhite});
    `}

${(p) =>
    p.iconType === "lock" &&
    !p.red &&
    css`
      background-image: url(${lockIconBlue});
    `}

    ${(p) =>
    p.iconType === "lock" &&
    p.red &&
    css`
      background-image: url(${lockIconRed});
    `}


${"" /* icon passed down */}
${(p) =>
    p.icon &&
    css`
      background-image: url(${p.icon});
    `}
`;

export const MenuButton = styled.div`
  cursor: pointer;
  height: 24px;
  position: relative;
  width: 24px;
  min-width: 24px;
  background-image: url(${(p) => (p.light ? moreIconWhite : moreIcon)});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const YesNoWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: ${(p) => p.theme.boxShadows.highlight};
  font-weight: ${(p) => p.theme.fontWeights.headerSmall};
  font-size: ${(p) => p.theme.fontSizes.headerXSmall};
  color: #fff;
  overflow: hidden;
`;

export const YesButton = styled.div`
  cursor: pointer;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  color: ${(p) => p.theme.colors.greenLight};
  background-color: ${(p) => p.theme.colors.greenDark};
`;

export const NoButton = styled(YesButton)`
  background-color: ${(p) => p.theme.colors.red};
  color: ${(p) => p.theme.colors.redLight};
`;
